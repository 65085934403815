import Cookies from 'universal-cookie';

import { Common } from './';

const cookies = new Cookies();

const set = (name: string, value: string, useDefaultPath = true) => {
  let path;

  if (useDefaultPath) {
    path = '/';
  }

  cookies.set(name, value, { path });
};

const get = (name: string) => {
  const param = cookies.get(name);

  return Common.checkType(param);
};

export default {
  set,
  get,
};
