import React, { ReactNode } from 'react';

import SubHeader from '../../common/SubHeader';
import Comments from '../../common/Comments';
import Block from '../../common/Block';
import Loader from '../../utils/Loader';
import Pagination, { PaginationOptions } from '../../utils/Pagination';

import './style.css';

type Props = {
  type?: string;
  dataLoaded?: boolean;
  mount?: boolean;
  pagination?: PaginationOptions;
  children?: ReactNode;
  extra?: ReactNode;
  loaderColor?: string;
  background?: string;
  comments?: {
    id: number;
  };
};

const DividedLayout = ({ type, dataLoaded, mount, pagination, children, extra, loaderColor, background, comments }: Props) => {
  return (
    <div className={`divided ${type}`}>
      <Block color={background || 'grey'}>
        {!pagination && <SubHeader project={type === 'project'} />}
        <div className="divided-wrap">
          <div className="divided-content">
            {mount && <div className="divided-body">{children}</div>}
            {!mount && (
              <div className="divided-loader">
                <Loader loaded={dataLoaded} color={loaderColor} />
              </div>
            )}
            {pagination && <Pagination {...pagination} secondary />}
            {comments && <Comments id={comments.id} />}
          </div>
          <div className="divided-extra">{extra}</div>
        </div>
      </Block>
    </div>
  );
};

export default DividedLayout;
