import React from 'react';
import { useHistory } from 'react-router-dom';
import { get as g } from 'lodash';

import { Common, translate } from '../../../functions';

import './style.css';

export type Product = {
  title: string;
  description?: string;
  body: {
    text: string;
    images: string[];
    title?: string;
    list?: string[];
  }[];
  productKey: string;
  links: string[];
  subLinks: string[];
  comments: number;
  active?: boolean;
};

type Props = {
  data: Product;
};

const Product = ({ data }: Props) => {
  const history = useHistory();

  const url = g(data, 'body[0].images[0]');
  const image = url ? Common.getImageUrl(url) : 'https://i.ibb.co/mHgBgrm/wp2163554-arduino-wallpapers.png';

  return (
    <div className="product-view" key={data.title} onClick={() => history.push(Common.link(data.productKey, { detail: 'product' }))}>
      <div className="product-view-header">
        <img className="product-view-image" src={image} alt={data.title} />
      </div>
      <div className="product-view-body">
        <div className="product-view-title">{data.title}</div>
        <div className="product-view-description">
          <span>{(data.body[0].text || '').substring(0, 170)}...</span>
          <span className="product-view-description-more">{translate('products.readMore')}...</span>
        </div>
      </div>
    </div>
  );
};

export default Product;
