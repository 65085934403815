import React from 'react';

import HexaSVG from '../../common/HexaSvg';

import './style.css';

const icon = (color = '#0B2C40') => ({ icon: 'hexa', color, width: 40, height: 40 });

type Props = {
  loaded?: boolean;
  color?: string;
};

const Loader = ({ loaded, color }: Props) => (
  <div className={`project-loader-holder ${loaded ? 'loaded' : ''}`}>
    <div className="project-loader-item">
      <HexaSVG {...icon(color)} />
    </div>
    <div className="project-loader-item">
      <HexaSVG {...icon(color)} />
    </div>
    <div className="project-loader-item">
      <HexaSVG {...icon(color)} />
    </div>
  </div>
);

export default Loader;
