import React from 'react';

import { isArray } from 'lodash';

import icons from '../../../assets/icons';

type Props = {
  icon: string;
  width?: number;
  height?: number;
  color?: string;
};

const Icon = ({ icon, color, width = 30, height = 30 }: Props) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const iconObject = icons[icon];

  if (!iconObject) {
    return null;
  }
  const content = <path d={iconObject.path} fill={color ? color : '#fff'} />;

  if (isArray(iconObject.path)) {
    return (
      <g transform={iconObject.transform} fill={color ? color : '#fff'}>
        {iconObject.path.map((p: string, index: number) => (
          <path d={p} key={index} />
        ))}
      </g>
    );
  }
  return (
    <svg width={width} height={height} viewBox={iconObject.viewBox}>
      {content}
    </svg>
  );
};

export default Icon;
