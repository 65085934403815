import React from 'react';

import OurTeam from '../../common/OurTeam';
import Block from '../../common/Block';

import Content from '../../content';

const About = () => {
  return (
    <>
      <Block color="white">
        <Content page="aboutUs" />
      </Block>
      <Block color="white">
        <OurTeam />
      </Block>
    </>
  );
};
export default About;
