import React, { useEffect, useState } from 'react';

// import API from '../../../api';

import BlogOverview, { Blog as BlogType } from '../../common/Blog';

import DividedLayout from '../../layout/DividedLayout';

import './style.css';

const Blog = () => {
  const [data, setData] = useState<BlogType | null>(null);
  const [mount, setMount] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      // const data = await API.get('/blogs/list');

      const data = {
        id: 1,
        title: 'Load local html resource on Android using webview',
        overview:
          'Date Started má i časovou složku, která je na Jiře povinná, nicméně pro naše účely není relevantní. Je úplně jedno, co do ní vyplníte, klidně ponechte aktuální čas, který tam předvyplňuje Jira.',
        user: 'Dominik Láclavík',
        createdAt: '1 Month ago',
        tags: ['Mobile', 'iOS'],
      } as BlogType;

      setData(data);

      setTimeout(() => setMount(true), 300);
    } catch (error) {}
  };

  return (
    <DividedLayout mount={mount} dataLoaded={!!data} extra={<div className="blog-info" />}>
      {data && <BlogOverview data={data} isDetail />}
    </DividedLayout>
  );
};

export default Blog;
