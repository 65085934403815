import React from 'react';

import Service from '../../common/Service';
import Block from '../../common/Block';

import config from '../../../assets/config';

const Services = () => {
  if (!config.services) return null;

  return (
    <>
      {config.services.map((service, i) => (
        <Block color={i % 2 ? 'lightGrey' : 'white'} key={i}>
          <div className="body-column">
            <Service data={service} />
          </div>
        </Block>
      ))}
    </>
  );
};

export default Services;
