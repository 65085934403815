import ReactGa from 'react-ga';
import { isNumber, kebabCase } from 'lodash';

import { Env } from './';

type LinkParams = {
  content?: boolean;
  detail?: string;
};

export type LinkData =
  | string
  | {
      page?: string;
      link?: string;
      name?: string;
    };

const link = (data: LinkData, params: LinkParams = {}) => {
  let path;

  if (typeof data === 'object') {
    path = (data.page || data.link || data.name) as string;
  } else {
    path = data as string;
  }

  path = `/${kebabCase(path)}`;

  if (params.content) {
    path = `/content${path}`;
  }

  if (params.detail) {
    path = `/${params.detail}${params.detail === 'blog' ? '' : 's'}${path}`;
  }

  if (Env.isProduction()) {
    ReactGa.pageview(path);
  }

  return path;
};

const dateFormat = (date: string | Date) => {
  const m = new Date(date);
  return (
    m.getUTCFullYear() +
    '/' +
    ('0' + (m.getUTCMonth() + 1)).slice(-2) +
    '/' +
    ('0' + m.getUTCDate()).slice(-2) +
    ' ' +
    ('0' + m.getUTCHours()).slice(-2) +
    ':' +
    ('0' + m.getUTCMinutes()).slice(-2) +
    ':' +
    ('0' + m.getUTCSeconds()).slice(-2)
  );
};

const getMonthName = (month: number) => {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  return monthNames[month];
};

function getLastMonths(number = 3) {
  const today = new Date();
  const last3Months = [];
  const month = today.getMonth() + 1;

  for (let i = 0; i < number; i++) {
    let m = month - i;

    if (m < 1) m = 11 - m * -1;

    last3Months.push(m);
  }

  return last3Months;
}

const getImageUrl = (image: string) => {
  return 'https://prinet.sk/api' + image;
};

const checkType = (param: any) => {
  if (isNumber(param)) {
    return Number(param);
  }

  switch (param) {
    case 'true':
      return true;

    case 'false':
      return false;

    case 'null':
      return null;

    case 'undefined':
      return undefined;

    default:
      return param;
  }
};

export default {
  link,
  checkType,
  dateFormat,
  getImageUrl,
  getMonthName,
  getLastMonths,
};
