import React from 'react';
import { useHistory } from 'react-router-dom';

import { Common, translate } from '../../../functions';

import Icon from '../SVG';

import './style.css';

export type Blog = {
  id: number;
  title: string;
  overview: string;
  user: string;
  createdAt: string;
  tags: string[];
};

type Props = {
  data: Blog;
  isDetail?: boolean;
};

const Blog = ({ data, isDetail }: Props) => {
  const history = useHistory();

  const renderTags = () =>
    data.tags.map((t) => (
      <div key={t} className="blog-tag">
        {t}
      </div>
    ));
  const icon = (name: string) => <Icon icon={name} height={12} width={12} color="#ccc" />;

  return (
    <div className="blog-overview" onClick={() => !isDetail && history.push(Common.link(data.id.toString(), { detail: 'blog' }))}>
      <h3 className="blog-title">{data.title}</h3>
      {!isDetail && <p className="blog-content">{data.overview}</p>}
      <div className="blog-extra">
        <div className="blog-extra-group">
          <div className="blog-name">
            {icon('user')}&nbsp;
            {data.user}
          </div>
          <div className="blog-time">
            {icon('clock')}&nbsp;
            {data.createdAt}
          </div>
          <div className="blog-tags">
            {icon('tags')}&nbsp;
            {renderTags()}
          </div>
        </div>
        {!isDetail && (
          <div className="blog-read">
            {translate('utils.readMore')} {'>>'}
          </div>
        )}
      </div>
      {isDetail && <p className="blog-content">{data.overview}</p>}
    </div>
  );
};

export default Blog;
