import React from 'react';

import HexaSVG from '../../common/HexaSvg';

import './style.css';

export type ListItemProps = {
  data: {
    icon?: string;
    page?: string;
    title?: string;
  };
  text: string;
  title: string;
  onClick?: () => void;
};

const ListItem = ({ data, text, title, onClick }: ListItemProps) => {
  if (data.icon) {
    return (
      <div className={`list-item ${data.page ? 'link' : ''}`} onClick={onClick}>
        <div className="list-item-icon">
          <HexaSVG icon={data.icon} width={70} height={70} color={data.page ? '#E56F16' : '#0B2C40'} />
        </div>
        <div className="list-item-title">{title}</div>
        <div className="list-item-text">{text}</div>
      </div>
    );
  }

  if (data.title) {
    return (
      <div className="list-item-block">
        <div className="list-item-block-title">{title}</div>
        <div>{text}</div>
      </div>
    );
  }

  return (
    <div className="list-item-line">
      <div className="list-item-line-text">
        <span>-&nbsp;&nbsp;</span>
        <span>{text}</span>
      </div>
    </div>
  );
};

export default ListItem;
