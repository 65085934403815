import React from 'react';
import { useHistory } from 'react-router-dom';

import { Common } from '../../../functions';

import Button from '../Button';
import Icon from '../SVG';

import iot from '../../../assets/images/iot.jpg';
import print3D from '../../../assets/images/3Dprint.png';

import './style.css';

type Props = {
  data: any;
};

const Interest = ({ data }: Props) => {
  const images = {
    iot,
    ['3Dprint']: print3D,
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const src = images[data.id] as string;

  const history = useHistory();

  return (
    <div className="service" key={data.name}>
      <div className="about">
        <div className="text">
          <div className="title">{data.name}</div>
          <div className="description">{data.description}</div>
          {data.focus &&
            data.focus.map((item: Record<string, string>) => (
              <div className="focus" key={item.name}>
                <Icon icon="hexagon" color="#E56F16" height={17} width={17} />
                <div className="label">{item.name}</div>
              </div>
            ))}
        </div>
        <div className="img">
          <img className="image" src={src} alt="service" />
        </div>
      </div>
      {data.sections && (
        <div className="sections">
          {data.sections.map((f: Record<string, any>) => (
            <div className="section" key={f.name}>
              <div className="icon">{f.icon}</div>
              <div className="name">{f.name}</div>
              <div className="description">{f.description}</div>
              {f.focus &&
                f.focus.map((item: Record<string, string>) => (
                  <div className="focus" key={item.name}>
                    <Icon icon="hexagon" color="#E56F16" height={14} width={14} />
                    <div className="label">{item.name}</div>
                  </div>
                ))}
            </div>
          ))}
        </div>
      )}
      {data.button && (
        <Button primary lg className="button" onClick={() => history.push(Common.link(data.button))}>
          {data.button.name}
        </Button>
      )}
    </div>
  );
};

export default Interest;
