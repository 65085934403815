import request, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { isObject, isArray } from 'lodash';
import { Env } from '../functions';
// import settings from '../assets/settings';
import { SimpleObject, UnsafeAny } from '@ts/General';

const validRequest = (resp: AxiosResponse) => {
  return (resp.status === 200 || resp.status === 201) && (!resp.data || isArray(resp.data) || isObject(resp.data)); // wtf
};

const ALLOWED_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

const forceUseProduction = false;
export const apiPath = Env.isProduction() || forceUseProduction ? 'https://server.api-unity.com/prinet' : 'http://localhost:8080/prinet';

type This = {
  path: string;
  params: AxiosRequestConfig;
  postfix: string;
};

const Api = function (this: This, params: AxiosRequestConfig = {}) {
  this.path = apiPath;
  this.params = params;
  this.postfix = '';
};

Api.prototype.headers = function (this: This, header: string) {
  return new (Api as UnsafeAny)({ ...this.params, header });
};

Api.prototype.data = function (data: SimpleObject) {
  return new (Api as UnsafeAny)({ ...this.params, data });
};

Api.prototype.get = function (url: string) {
  return new (Api as UnsafeAny)({ ...this.params, method: ALLOWED_METHODS.GET, url });
};

Api.prototype.post = function (url: string) {
  return new (Api as UnsafeAny)({ ...this.params, method: ALLOWED_METHODS.POST, url });
};

Api.prototype.put = function (url: string) {
  return new (Api as UnsafeAny)({ ...this.params, method: ALLOWED_METHODS.PUT, url });
};

Api.prototype.delete = function (url: string) {
  return new (Api as UnsafeAny)({ ...this.params, method: ALLOWED_METHODS.DELETE, url });
};

Api.prototype.send = function () {
  return new Promise((resolve, reject) => {
    const { method, headers, data, url } = this.params;

    const req = {
      baseURL: this.path,
      method,
      url: url + this.postfix,
      headers: {
        // 'X-API-KEY': settings.apiKey,
        ...(headers || {}),
      },
      data: data || {},
      withCredentials: true,
      xhrFields: {
        withCredentials: true,
      },
    };

    request(req)
      .then((response) => (validRequest(response) ? resolve(response.data) : reject(response)))
      .catch((response) => reject(response));
  });
};

export default new (Api as UnsafeAny)();
