import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonBase } from '@mui/material';

import { Common } from '../../../functions';

const parseOptions = (string: string) => {
  /** Cut off type **/
  let cutIndex = string.slice(2).indexOf(':') + 3;
  let type = string.slice(0, cutIndex).replace(/:/g, '');

  const link = type;

  /** Cut off length **/
  string = string.slice(cutIndex);

  cutIndex = string.slice(2).indexOf(':') + 3;
  const length = Number(string.slice(0, cutIndex).replace(/:/g, ''));
  const text = string.slice(cutIndex);

  if (type !== 'highlight') {
    type = 'link';
  }

  return { text, type, length, link };
};

/** Map highlights & links **/
const mapStringToXML = (string: string, redirect: (link: string) => void) => {
  const spread = string.split(':spread');

  if (spread.length < 2) {
    return string;
  }

  const items: (ReactNode | string)[] = [];

  spread.forEach((s, i) => {
    if (s.startsWith('::')) {
      const { type, text, length, link } = parseOptions(s);

      const item = (
        <span key={i} className={`word-${type}`}>
          {text.slice(0, length)}
        </span>
      );

      if (type === 'link') {
        items.push(
          <ButtonBase onClick={() => redirect(Common.link(link))} key={i}>
            {item}
          </ButtonBase>
        );
      } else {
        items.push(item);
      }

      items.push(text.slice(length));
    } else {
      items.push(s);
    }
  });

  return items;
};

type Props = {
  highlights?: string[] | { text: string; page: string }[];
  children: string;
  links?: string[];
};

const TextMarker = ({ highlights = [], children, links = [] }: Props) => {
  const history = useHistory();

  const itemsToHighlight = [...highlights, ...links];

  let newString = children;

  if (children?.length && itemsToHighlight.length) {
    itemsToHighlight.forEach((h) => {
      const matchString = typeof h === 'string' ? h : h.text;

      const index = newString.indexOf(matchString);

      if (index !== -1) {
        const start = newString.slice(0, index);
        const replaced = `:spread::${typeof h === 'string' ? h : h.page || 'highlight'}::${matchString.length}:${matchString}`;
        const end = newString.slice(index + matchString.length);

        if (end[0].match(/[\d\w]/g)) return newString;

        newString = `${start}${replaced}${end}`;
      }
    });

    return mapStringToXML(newString, history.push);
  }

  return children;
};

export default TextMarker;
