import React from 'react';
import './style.css';

const Blog = () => {
  return (
    <div className="projects-blog-holder">
      <div className="project-blogs"></div>
    </div>
  );
};

export default Blog;
