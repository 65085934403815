import React, { useState } from 'react';

import Block from '../../common/Block';
import Button from '../../common/Button';

import API from '../../../api';

import { Map, translate } from '../../../functions';

import TextField from '../../common/TextField';
import config from '../../../assets/config';

import hexaIcons, { CONTACT } from '../../../assets/hexaIcons';
import HexaSVG from '../../common/HexaSvg';

import './style.css';

type FormValues = {
  fullName?: string;
  email?: string;
  phone?: string;
  message?: string;
};

const Form = () => {
  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);
  const [failed, setFailed] = useState(false);
  const [values, setValues] = useState<FormValues>({});
  const [error, setError] = useState<string | null>(null);

  /**
   * Update input
   * @param {Object} e
   */
  const updateInput = (e: any) => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });
    setError(null);
  };

  const requiredFields = () => {
    const mapped = config.contact.form.fields.filter((f) => f.required).map((f) => f.id);

    return [...mapped, 'message'];
  };

  const onSend = async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const missing = requiredFields().find((f) => !values[f]);

    if (missing) {
      setError(missing);
      return;
    }

    if (sending) {
      return;
    }

    setSending(true);

    /** API **/
    const data = {
      ...values,
      name: values.fullName,
    };

    try {
      await API.post('/message/create', data);

      setSent(true);
      setSending(false);
    } catch (error) {
      setFailed(true);
      setSending(false);
    }
  };

  const renderForm = () => {
    if (sent) {
      return (
        <div className="contact-form">
          <div className="sent">
            <HexaSVG icon="check" width={70} height={70} />
            <div className="text">{translate('contact.form.sent')}</div>
          </div>
        </div>
      );
    }

    return (
      <div className="contact-form">
        <div className="header">{translate('contact.form.title')}</div>
        {config.contact.form.fields.map((f, index) => (
          <div className="field" key={index}>
            <TextField
              label={translate(`contact.form.fields[${index}].label`)}
              placeholder={translate(`contact.form.fields[${index}].placeholder`)}
              name={f.id}
              required={f.required}
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              // @ts-ignore
              value={values[f.id] || ''}
              onChange={updateInput}
              error={error === f.id}
            />
          </div>
        ))}
        <div className="field">
          <div className={`area-label ${error === 'message' ? 'error' : ''}`}>{translate('contact.form.message')} *</div>
          <textarea className={`area ${error === 'message' ? 'error' : ''}`} name="message" value={values.message || ''} onChange={updateInput} />
        </div>
        <div className="buttons">
          <Button lg primary loading={sending} onClick={() => onSend()}>
            {translate('contact.form.button')}
          </Button>
        </div>
        {failed && <div className="message-error">{translate('contact.form.failed')}</div>}
      </div>
    );
  };

  return (
    <div className="contact">
      <Block color="white">
        <div className="title">{translate('contact.title')}</div>
        <div className="icons">
          {Object.keys(hexaIcons)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .filter((key) => hexaIcons[key].type === CONTACT)
            .map((key) => (
              <HexaSVG icon={key} key={key} width={50} height={50} />
            ))}
        </div>
        <div className="row">
          {renderForm()}
          <div className="company-info">
            <div className="header">{translate('contact.company.title')}</div>
            {config.contact.company.list.map((item, index) => (
              <div className="item" key={index}>
                <div className="name">{translate(`contact.company.list[${index}].title`)}</div>
                <div className="subTitle">{translate(`contact.company.list[${index}].subTitle`)}</div>
                <div className="content">{translate(`contact.company.list[${index}].content`)}</div>
              </div>
            ))}
          </div>
        </div>
      </Block>
      {/** <div className="map">{Map()}</div> **/}
    </div>
  );
};

export default Form;
