import React, { useEffect, useState } from 'react';

import API from '../../../api';

import { Filter, translate } from '../../../functions';

import DividedLayout from '../../layout/DividedLayout';
import Icon from '../../common/SVG';
import BlogOverview, { Blog as BlogType } from '../../common/Blog';

import './style.css';

type BlogStats = {
  total: number;
  comments: number;
  tags: number;
};

type BlogList = {
  rows: BlogType[];
  total: number;
};

const Blog = () => {
  const [data, setData] = useState<BlogList>({ rows: [], total: 0 });
  // const [filter] = useState<{ limit: number; offset: number }>({ limit: 20, offset: 0 });
  const [tags, setTags] = useState<{ name: string; total: number }[]>([]);
  const [stats, setStats] = useState<BlogStats>({ total: 0, comments: 0, tags: 0 });
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [mount, setMount] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');

  useEffect(() => {
    loadData();
    loadTags();
    loadStats();
  }, []);

  const loadStats = () => {
    const stats = { total: 9, comments: 3, tags: 5 };

    setStats(stats);
  };

  const loadTags = async () => {
    const tags = [
      { name: 'Mobile', total: 3 },
      { name: 'iOS', total: 2 },
      { name: 'Web', total: 1 },
      { name: 'Development', total: 4 },
      { name: 'React', total: 1 },
      { name: 'API', total: 4 },
      { name: 'XCode', total: 3 },
    ];

    setTags(tags);
  };

  const loadData = async () => {
    try {
      // await API.post('/blog/search', { search: searchInput });

      const data = {
        rows: [
          {
            id: 1,
            title: 'Load local html resource on Android using webview',
            overview:
              'Date Started má i časovou složku, která je na Jiře povinná, nicméně pro naše účely není relevantní. Je úplně jedno, co do ní vyplníte, klidně ponechte aktuální čas, který tam předvyplňuje Jira.',
            user: 'Dominik Láclavík',
            createdAt: '1 Month ago',
            tags: ['Mobile', 'iOS'],
          },
          {
            id: 2,
            title: 'Do you prefer physical books or ebooks?',
            overview:
              'Date Started má i časovou složku, která je na Jiře povinná, nicméně pro naše účely není relevantní. Je úplně jedno, co do ní vyplníte, klidně ponechte aktuální čas, který tam předvyplňuje Jira.',
            user: 'Dominik Láclavík',
            createdAt: '1 Month ago',
            tags: ['Question', 'Web'],
          },
          {
            id: 3,
            title: 'What countries have you traveled to?',
            overview:
              'Date Started má i časovou složku, která je na Jiře povinná, nicméně pro naše účely není relevantní. Je úplně jedno, co do ní vyplníte, klidně ponechte aktuální čas, který tam předvyplňuje Jira.',
            user: 'Dominik Láclavík',
            createdAt: '1 Month ago',
            tags: ['Travel', 'Swift', 'API', 'XCode'],
          },
          {
            id: 4,
            title: 'What are your goals for the next two years?',
            overview:
              'Date Started má i časovou složku, která je na Jiře povinná, nicméně pro naše účely není relevantní. Je úplně jedno, co do ní vyplníte, klidně ponechte aktuální čas, který tam předvyplňuje Jira.',
            user: 'Dominik Láclavík',
            createdAt: '1 Month ago',
            tags: ['App Store', 'Projects'],
          },
          {
            id: 5,
            title: 'What was the best reason why you ever faked being sick?',
            overview:
              'Date Started má i časovou složku, která je na Jiře povinná, nicméně pro naše účely není relevantní. Je úplně jedno, co do ní vyplníte, klidně ponechte aktuální čas, který tam předvyplňuje Jira.',
            user: 'Dominik Láclavík',
            createdAt: '1 Month ago',
            tags: ['Mobile', 'Unity'],
          },
          {
            id: 6,
            title: 'Load local html resource on Android using webview',
            overview:
              'Date Started má i časovou složku, která je na Jiře povinná, nicméně pro naše účely není relevantní. Je úplně jedno, co do ní vyplníte, klidně ponechte aktuální čas, který tam předvyplňuje Jira.',
            user: 'Dominik Láclavík',
            createdAt: '1 Month ago',
            tags: ['Mobile', 'iOS'],
          },
          {
            id: 7,
            title: 'Verify your identity with FaceRecognition 2.0',
            overview:
              'Date Started má i časovou složku, která je na Jiře povinná, nicméně pro naše účely není relevantní. Je úplně jedno, co do ní vyplníte, klidně ponechte aktuální čas, který tam předvyplňuje Jira.',
            user: 'Dominik Láclavík',
            createdAt: '1 Month ago',
            tags: ['Web', 'Development', 'React'],
          },
        ],
        total: 9,
      };

      setData(data);
      setDataLoaded(true);

      setTimeout(() => setMount(true), 300);
    } catch (error) {
      console.log(error);
    }
  };

  const blogs = data.rows.map((item: BlogType) => <BlogOverview data={item} key={item.id} />);

  const onSearch = () => {
    loadData();
  };

  console.log(blogs);

  const renderExtra = () => {
    return (
      <div className="blog-info">
        <div className="blog-search-wrap">
          <label className="blog-search-label">
            <input
              placeholder={`${translate('utils.searchFor')}...`}
              className="blog-search-input"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </label>
          <button className="blog-search-button" onClick={() => onSearch()}>
            {translate('utils.go')}!
          </button>
        </div>
        <div className="blog-stats-wrap">
          <div className="blog-stats-title">{translate('blog.title')}</div>
          <div className="blog-stats">
            <div className="blog-stat">
              <div className="blog-stat-value">{stats.total}</div>
              <div className="blog-stat-name">{translate('blog.posts')}</div>
            </div>
            <div className="blog-stat">
              <div className="blog-stat-value">{stats.comments}</div>
              <div className="blog-stat-name">{translate('blog.comments')}</div>
            </div>
            <div className="blog-stat">
              <div className="blog-stat-value">{stats.tags}</div>
              <div className="blog-stat-name">{translate('blog.tags')}</div>
            </div>
          </div>
        </div>
        <div className="blog-tags-cloud">
          <div className="blog-tags-cloud-title">
            <Icon icon="tags" width={14} height={14} color="#777" />
            &nbsp;&nbsp;{translate('blog.tagCloud')}
          </div>
          <div className="blog-tags-cloud-items">
            {tags.map((tag) => (
              <div className="blog-tag" key={tag.name}>
                {tag.name} ({tag.total})
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <DividedLayout
      mount={mount}
      dataLoaded={dataLoaded}
      pagination={{
        currentPage: 1,
        total: data.total,
        onChange: (index) => {
          Filter.update({ limit: index });
          loadData();
        },
      }}
      extra={renderExtra()}
    >
      {dataLoaded && blogs}
    </DividedLayout>
  );
};

export default Blog;
