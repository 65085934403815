import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Common, translate } from '../../../functions';

import Block from '../../common/Block';
import Interest, { Interest as InterestType } from '../../common/Interest';
import HexaSVG from '../../common/HexaSvg';
import HexaTooltip from '../../common/HexaTooltip';

import hexaIcons, { FEATURE } from '../../../assets/hexaIcons';
import config from '../../../assets/config';

import './style.css';

const Home = () => {
  const [tooltip, setTooltip] = useState<number | null>(null);

  const history = useHistory();

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval> | null = null;

    const startTimeout = setTimeout(() => {
      setTooltip(0);
      intervalId = setInterval(() => showNextTooltip(), 5000);
    }, 2000);

    return () => {
      // Clear the interval and timeout when the component unmounts
      if (intervalId) {
        clearInterval(intervalId);
      }
      clearTimeout(startTimeout);
    };
  }, []); // Empty dependency array ensures that this effect runs only once on mount

  const showNextTooltip = () => {
    setTooltip((prevTooltip) => {
      let newTooltip = (prevTooltip || 0) + 1;

      if (newTooltip === featureIcons.length) newTooltip = 0;

      return newTooltip;
    });
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const featureIcons = Object.keys(hexaIcons).filter((key) => hexaIcons[key].type === FEATURE);

  return (
    <>
      <Block color="gradient" hideOnSmallRes>
        <div className="contain-intro">
          <div className="overview"></div>
        </div>
      </Block>
      {config.interests && (
        <Block color="white">
          <div className="body-row">
            {(config.interests as InterestType[]).map((i) => (
              <Interest data={i} key={i.name} />
            ))}
          </div>
        </Block>
      )}
      <Block color="grey" overflow>
        <div className="contain-intro">
          <div className="hexa-line">
            {featureIcons.map((key, i) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <HexaTooltip className="hexa-item" title={hexaIcons[key].title} hidden={i !== tooltip} key={key}>
                <div className={`hexa-item-animation ${i === tooltip ? 'active' : ''}`}>
                  <HexaSVG icon={key} width={50} height={50} />
                </div>
              </HexaTooltip>
            ))}
          </div>
        </div>
      </Block>
      <Block color="white">
        <div className="banner">
          <div className="banner-title">{translate('contact.interested')}</div>
          <div className="banner-text" onClick={() => history.push(Common.link('/contact'))}>
            {translate('contact.title')}!
          </div>
        </div>
      </Block>
    </>
  );
};

export default Home;
