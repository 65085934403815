import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import API from '../../../api';

import { replace } from 'lodash';

import { Common, translate } from '../../../functions';

import DividedLayout from '../../layout/DividedLayout';
import Comments from '../../common/Comments';
import HexaSVG from '../../common/HexaSvg';
import ListItem from '../../content/ListItem';

import { LinkData } from '@functions/common';
import { ListItemProps } from '@mui/material';

import { Project as ProjectType } from '@common/Project';

import './style.css';

const Project = () => {
  const [unmount, setUnmount] = useState(false);
  const [mount, setMount] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [data, setData] = useState<ProjectType | Record<string, any>>({});

  const projectKey = replace(window.location.pathname, '/projects/', '');

  const history = useHistory();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const data = await API.get(`/projects/${projectKey}`);

      setData(data);
      setDataLoaded(true);

      setTimeout(() => setMount(true), 300);
    } catch (e) {
      API.err(e);
    }
  };

  const goTo = (link: LinkData) => {
    setUnmount(true);

    link = Common.link(link, { content: true });

    setTimeout(() => history.push(link as string), 150);
  };

  const renderSubLinks = () => {
    if (!data.subLinks) return null;

    return (
      <>
        <div className="project-body-title">{translate('page.project.subLinks.title')}</div>
        <div className="project-body-sub-links">
          {data.subLinks.links &&
            data.subLinks.links.map((l: string, i: number) => (
              <div className="project-body-sub-link-item" onClick={() => goTo(l)} key={i}>
                <HexaSVG icon="hexa" width={90} height={90} color="#E56F16" />
                <div className="project-body-sub-link-text">
                  <span>{translate(`page.project.subLinks.links[${i}].text`)}</span>
                </div>
              </div>
            ))}
        </div>
      </>
    );
  };

  const renderLinks = () => {
    if (!data.links) return null;

    return (
      <div className="project-body-list">
        {data.links.map((item: ListItemProps, index: number) => (
          <ListItem
            data={item}
            key={index}
            text={translate(`page.project.links[${index}].text`)}
            title={translate(`page.project.links[${index}].title`)}
            onClick={() => goTo(item as LinkData)}
          />
        ))}
      </div>
    );
  };

  const renderPage = () => {
    const content = (
      <div className={`project ${unmount ? 'hide' : ''}`}>
        {data.title && <div className="project-title">{data.title}</div>}
        {data.description && <div className="project-description">{data.description}</div>}
        {data.body && (
          <div className="project-body">
            {data.body.map((item: any, index: number) => (
              <div className="project-body-section" key={index}>
                {item.subtitle && <div className="project-body-subtitle">{item.subtitle}</div>}
                {item.text && <div className="project-body-text">{item.text}</div>}
                {item.list && (
                  <div className="project-body-list">
                    {item.list.map((l: ListItemProps, i: number) => (
                      <ListItem
                        data={l}
                        key={i}
                        text={translate(`page.project.body[${index}].list[${i}].text`)}
                        title={translate(`page.project.body[${index}].list[${i}].title`)}
                      />
                    ))}
                  </div>
                )}
                {item.images && item.images[0] && (
                  <div className="project-body-image-wrap">
                    <img src={Common.getImageUrl(item.images[0])} className="project-body-image" alt={item.images[0]} />
                  </div>
                )}
                {/** item.images && item.images.length > 1 && item.images.map((image: string) => null) **/}
              </div>
            ))}
          </div>
        )}
        {renderSubLinks()}
        {renderLinks()}
      </div>
    );

    return content;
  };

  return (
    <DividedLayout
      type="project"
      dataLoaded={dataLoaded}
      mount={mount}
      loaderColor="#1b888c"
      extra={<div className="project-view-extra" />}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      comments={data.id}
    >
      {mount && renderPage()}
    </DividedLayout>
  );
};
export default Project;
