import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material';
import { BrowserRouter as Router, Route, useLocation, Switch, Redirect } from 'react-router-dom';

import { Token } from '@hooks/index';

import Layout from './components/layout';
import Home from '@pages/Home';
import Products from '@pages/Products';
import Projects from '@pages/Projects';
import Services from '@pages/Services';
import Blog from '@pages/Blog';
import About from '@pages/About';
import Contact from '@pages/Contact';
import Content from './components/content';
import Project from './components/details/project';
import Product from './components/details/product';
import BlogPost from './components/details/Blog';

import './style.scss';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#009BFE',
      light: '#30afff',
    },
    secondary: grey,
  },
  typography: {
    fontFamily: `Open Sans, Arial, Helvetica, Roboto, sans-serif`,
    h1: {
      fontSize: 46,
      fontWeight: 400,
    },
    h2: {
      fontSize: 40,
      fontWeight: 300,
    },
    h3: {
      fontSize: 30,
      fontWeight: 300,
    },
    h5: {
      fontSize: 20,
      fontWeight: 300,
    },
    h6: {
      fontSize: 18,
      fontWeight: 300,
    },
    subtitle1: {
      fontSize: 22,
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 400,
    },
    body1: {
      fontWeight: 300,
      fontSize: 15,
      lineHeight: 1.8,
    },
    body2: {
      fontWeight: 300,
      fontSize: 13,
    },
  },
});

const usePageViews = () => {
  const location = useLocation();

  useEffect(() => {
    // console.log(location.pathname);
    // ga.send(['pageview', location.pathname])
  }, [location]);
};

const useScrollRestoration = () => {
  const location = useLocation();

  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);
};

const Routes = () => {
  usePageViews();
  useScrollRestoration();
  const loggedIn = Token.useTokenValidation();

  if (loggedIn) {
    return (
      <Switch>
        <Redirect path="*" to="/visuals" />
      </Switch>
    );
  }

  return (
    <Layout>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/projects" component={Projects} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/about-us" component={About} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/content/:pageId" component={Content} />
        <Route exact path="/projects/*" component={Project} />
        <Route exact path="/products/*" component={Product} />
        <Route exact path="/blog/*" component={BlogPost} />
      </Switch>
    </Layout>
  );
};

export const App = () => (
  <ThemeProvider theme={theme}>
    <Router>
      <Routes />
    </Router>
  </ThemeProvider>
);
