import React from 'react';

import { Common } from '../../../functions';

import Button from '../Button';

import './style.css';
import { useHistory } from 'react-router-dom';

export type Interest = {
  name: string;
  description: string;
  features: {
    name: string;
    page: string;
  }[];
  button: {
    name: string;
    page: string;
  };
  en: Record<string, unknown>;
};

type Props = {
  data: Interest;
};

const Interest = ({ data }: Props) => {
  const history = useHistory();

  return (
    <div className="interest" key={data.name}>
      <div className="title">{data.name}</div>
      <div className="description">{data.description}</div>
      {data.features && (
        <div className="features">
          {data.features.map((f) => (
            <div key={f.name} className="feature" onClick={() => history.push(Common.link(f, { content: true }))}>
              {f.name}
            </div>
          ))}
        </div>
      )}
      {data.button && (
        <div>
          <Button primary lg link={data.button.page}>
            {data.button.name}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Interest;
