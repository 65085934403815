import includes from 'lodash.includes';
import { Env } from '@functions/index';

const isLocalhost = () => includes(window.location.hostname, 'localhost');
const isProduction = () => !isLocalhost();

const isAdmin = includes(window.location.pathname, 'admin');

const apiUrl = () => (Env.isLocalhost() ? 'http://localhost:8080' : 'https://server.api-unity.com');

export default {
  isProduction,
  isLocalhost,
  isAdmin,
  apiUrl,
};
