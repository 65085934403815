import React, { useState, useEffect } from 'react';
import { sortBy } from 'lodash';

import API from '../../../api';
import Project, { Project as ProjectType } from '../../common/Project';
import BlogPreview from '../../common/BlogPreview';

import DividedLayout from '../../layout/DividedLayout';

import './style.css';

export type ProjectList = {
  rows?: ProjectType[];
  total?: number;
};

const Projects = () => {
  const [data, setData] = useState<ProjectList>({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [mount, setMount] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const response = await API.get('/projects/list');
      const newData = response.data;

      setData(newData);
      setDataLoaded(true);

      setTimeout(() => setMount(true), 300);
    } catch (error) {
      // Handle the error as needed
    }
  };

  const projects = sortBy(data.rows || [], 'sequence')
    .filter((p) => p.active)
    .map((project) => <Project data={project} key={project.projectKey} />);

  return (
    <DividedLayout
      type="projects"
      dataLoaded={dataLoaded}
      mount={mount}
      loaderColor="#1b888c"
      extra={<BlogPreview />}
      pagination={{
        currentPage: 1,
        total: 2,
        onChange: () => loadData(),
      }}
    >
      <div className="projects-items">{projects}</div>
    </DividedLayout>
  );
};

export default Projects;
