import { Cookie } from './';

const get = (newParams: Record<string, unknown>) => {
  const params = { limit: 10, offset: 0, ...newParams };

  return { ...params, ...JSON.parse(Cookie.get('filter') || '{}') };
};

const update = (newParams: Record<string, unknown>) => {
  let params = JSON.parse(Cookie.get('filter') || {});

  params = { ...params, ...newParams };

  Cookie.set('filter', JSON.stringify(params));
};

export default {
  update,
  get,
};
