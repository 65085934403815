import React, { useEffect, useState } from 'react';

import API from '../../../api';

import { translate } from '../../../functions';

import './style.css';

export type Comment = {
  id: number;
  content: string;
  createdAt: string;
  createdByNick: string;
};

type Props = {
  id: number;
};

const Comments = ({ id }: Props) => {
  const [comments, setComments] = useState<Comment[]>([]);

  const [nick, setNick] = useState('');
  const [comment, setComment] = useState('');

  const loadData = async () => {
    try {
      const data = await API.post('/comments/search', { id });

      setComments(data.rows as Comment[]);
    } catch (e) {
      API.err(e);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [id]);

  const addComment = async () => {
    try {
      await API.post('/comments/create', { nick, comment, id });

      setNick('');
      setComment('');

      loadData();
    } catch (e) {
      API.err(e);
    }
  };

  return (
    <div className="comments">
      <div className="comments-title">{translate('utils.comments')}</div>
      {comments.map((c) => (
        <div className="comment">
          <div className="comment-user">{c.createdByNick}</div>
          <div className="comment-text">{c.content}</div>
          <div className="comment-time">{c.createdAt}</div>
        </div>
      ))}
      <input className="new-comment-input" value={nick} placeholder={translate('utils.yourNick')} onChange={(e) => setNick(e.target.value)} />
      <label className="new-comment">
        <textarea
          className="new-comment-area"
          value={comment}
          placeholder={translate('utils.yourComment')}
          onChange={(e) => setComment(e.target.value)}
        />
        <div>
          <button className="new-comment-button" onClick={() => addComment()}>
            {translate('utils.addComment')}
          </button>
        </div>
      </label>
    </div>
  );
};

export default Comments;
