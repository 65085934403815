import React, { ReactNode } from 'react';

import './style.css';

type Props = {
  position?: string;
  title?: string;
  className?: string;
  children: ReactNode;
  hidden?: boolean;
};

const HexaTooltip = ({ position, title, children, className, hidden }: Props) => {
  return (
    <div className={`tooltip ${className || ' '}`}>
      {title && <div className={`text  ${position || 'top-right'} ${hidden ? 'hidden' : ''}`}>{title}</div>}
      {children}
    </div>
  );
};

export default HexaTooltip;
