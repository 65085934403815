import React from 'react';

import HexaSVG from '../../common/HexaSvg';

import './style.css';

const NotFound = () => {
  return (
    <div className="not-found">
      <div className="icon">
        <HexaSVG icon="notFound" width={50} height={50} color={window.location.pathname.startsWith('/project') ? '#1b888c' : undefined} />
      </div>
      <div className="text">THE PAGE WAS NOT FOUND</div>
    </div>
  );
};

export default NotFound;
