import React from 'react';
import { useHistory } from 'react-router-dom';

import { Common } from '../../../functions';

import { kebabCase } from 'lodash';

import './style.css';

type Props = {
  label: string;
  navigating: boolean;
  primary?: boolean;
  path?: string;
  active?: boolean;
  secondary?: boolean;
};

const getClassProps = (props: Partial<Props>) => `${props.secondary ? 'secondary' : ''} ${props.primary ? 'primary' : ''}`;

const NavItem = ({ label, path, active, navigating, ...props }: Props) => {
  const redirect = path ? path : `/${kebabCase(label)}`;
  const history = useHistory();

  if (navigating) {
    active = active || window.location.pathname === redirect;
  }

  return (
    <button className={`nav ${active ? 'active' : ''} ${getClassProps(props)}`} onClick={() => history.push(Common.link(redirect))}>
      <span className="label">{label}</span>
    </button>
  );
};

export default NavItem;
