import React from 'react';

import { get as g } from 'lodash';

import config from '../../../prinet-web/src/assets/config';

import TextMarker from '../components/common/TextMarker';

const translate = (key: string): string => {
  const text = g(config, key);
  const highlights = g(config, `${key.replace(/.\w+$/g, '')}.highlights`);
  const links = g(config, `${key.replace(/.\w+$/g, '')}.links`);

  if (highlights || links) {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <TextMarker highlights={highlights as any} links={links as unknown as string[]}>
        {text as string}
      </TextMarker>
    ) as unknown as string;
  }

  if (text === undefined) {
    return '';
  }

  return text as string;
};

export default translate;
