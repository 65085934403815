import React from 'react';

import config from '../../../assets/config';

import lv from '../../../assets/images/members/lv.jpg';
import dl from '../../../assets/images/members/dl.jpg';
import rv from '../../../assets/images/members/rv.jpg';

import './style.css';

type Position = {
  id: string;
  name: string;
  position: string;
};

const person = (data: Position) => {
  const images = {
    lv,
    dl,
    rv,
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const image = images[data.id];

  return (
    <div className="member" key={data.id}>
      <div className="photo">
        <img src={image} width="100%" alt={data.id} />
      </div>
      <div className="name">{data.name}</div>
      <div className="position">{data.position}</div>
    </div>
  );
};

const OurTeam = () => {
  if (!config.ourTeam) {
    return null;
  }

  return (
    <div className="body-column">
      <div className="body-title">Meet our core team</div>
      <div className="body-row">{config.ourTeam.map((member) => person(member))}</div>
    </div>
  );
};

export default OurTeam;
