export const PAGES = {
  print: '3DPrint',
  printPrototyping: '3DPrototyping',
  materialsAndProcedures: 'materialsAndProcedures',
  printerSelectionTips: 'printerSelectionTips',

  intelligentSystems: 'intelligentSystems',
  iotSolutions: 'iotSolutions',
  networks: 'networks',
  communicationElements: 'communicationElements',

  technicalChemistry: 'technicalChemistry',
  chemistryOfMaterials: 'chemistryOfMaterials',
  chemistryForElectronics: 'chemistryForElectronics',

  materialPLA: 'materialPla',
  materialPET: 'materialPet',
  materialPETG: 'materialPetg',
  materialABS: 'materialAbs',
  materialTPE: 'materialTpe',
  materialPolyamid: 'materialPolyamid',

  visuals: 'visuals',
  filaments: 'filaments',
};

const config = {
  interests: [
    {
      name: '3D Print',
      description:
        'Možnosti sú obrovské navyše v prípade 3D tlače platí, že zložitosť nezvyšuje cenu. Zmeny vo výrobe vyskúšané týmito technológiami sú veľmi šetrné ekonomicky a ekologicky. Nevyhnutne bude 3D tlač prechádzať naďalej rapídnym vývojom a bude podstatne meniť výrobnú sféru.',
      features: [
        { name: 'Materiály a postupy', page: PAGES.materialsAndProcedures },
        { name: '3D Prototyping', page: PAGES.printPrototyping },
      ],
      button: { name: 'Čítať viac', page: PAGES.print },
      en: {
        name: '3D Print',
        description:
          'Možnosti sú obrovské navyše v prípade 3D tlače platí, že zložitosť nezvyšuje cenu. Zmeny vo výrobe vyskúšané týmito technológiami sú veľmi šetrné ekonomicky a ekologicky. Nevyhnutne bude 3D tlač prechádzať naďalej rapídnym vývojom a bude podstatne meniť výrobnú sféru.',
        icon: '',
        features: [
          { name: 'Materiály a postupy', page: PAGES.materialsAndProcedures },
          { name: '3D Prototyping', page: PAGES.printPrototyping },
        ],
        button: { name: 'Čítať viac', page: PAGES.print },
      },
    },
    {
      name: 'IoT Riešenia',
      description:
        'Inteligentné systémy navzájom komunikujúce a prepojené v tzv. „Internete vecí“ (IoT, Internet of Things) predstavujú revolúciu v komunikácií, priemysle, biznise a spoločnosti vôbec. Možnosť zapojiť obrovské množstvo senzorov, smart zariadení a vstavaných (embedded) systémov tvoriacich funkčné časti väčších celkov.',
      features: [
        { name: 'Inteligentné systémy', page: PAGES.intelligentSystems },
        { name: 'Siete', page: PAGES.networks },
        { name: 'Komunikačné prvky', page: PAGES.communicationElements },
      ],
      button: { name: 'Čítať viac', page: PAGES.iotSolutions },
      en: {
        name: 'IoT Riešenia',
        description:
          'Inteligentné systémy navzájom komunikujúce a prepojené v tzv. „Internete vecí“ (IoT, Internet of Things) predstavujú revolúciu v komunikácií, priemysle, biznise a spoločnosti vôbec. Možnosť zapojiť obrovské množstvo senzorov, smart zariadení a vstavaných (embedded) systémov tvoriacich funkčné časti väčších celkov.',
        icon: '',
        features: [
          { name: 'Inteligentné systémy', page: PAGES.intelligentSystems },
          { name: 'Siete', page: PAGES.networks },
          { name: 'Komunikačné prvky', page: PAGES.communicationElements },
        ],
        button: { name: 'Čítať viac', page: PAGES.iotSolutions },
      },
    },
    {
      name: 'Technická chémia',
      description:
        'Možnosti technickej chémie sú neobmedzené a neustále sa zdokonaľujú postupy, ktoré možno priamo aplikovať vo výrobe, spracovaní, ochranných opatreniach pre rôzne materiály a výrobky z nich. My využívame naše skúsenosti pre návrh rôznych postupov s využitím ekonomicky a ekologicky vhodnej chémie.',
      features: [
        { name: 'Chémia materíalov', page: PAGES.chemistryOfMaterials },
        { name: 'Chémia pre elektroniku', page: PAGES.chemistryForElectronics },
      ],
      button: { name: 'Čítať viac', page: PAGES.technicalChemistry },
      en: {
        name: 'Technická chémia',
        description:
          'Možnosti technickej chémie sú neobmedzené a neustále sa zdokonaľujú postupy, ktoré možno priamo aplikovať vo výrobe, spracovaní, ochranných opatreniach pre rôzne materiály a výrobky z nich. My využívame naše skúsenosti pre návrh rôznych postupov s využitím ekonomicky a ekologicky vhodnej chémie.',
        features: [
          { name: 'Chémia materíalov', page: PAGES.chemistryOfMaterials },
          { name: 'Chémia pre elektroniku', page: PAGES.chemistryForElectronics },
        ],
        button: { name: 'Čítať viac', page: PAGES.technicalChemistry },
      },
    },
  ],
  services: [
    {
      id: 'iot',
      name: 'IoT Riešenia',
      info: 'IoT je skratka pre tzv. „internet vecí“ z angl. „Internet of Thing“ predstavuje fyzickú a logickú sieť s prepojením miliónov zariadení, senzorov a pracovných staníc s jedinečnou identifikáciou podľa unikátnej IP adresy. IoT umožňuje výmenu obrovského množstva dát z ľubovoľných z ľubovoľných prvkov systémov. Vhodne spracované dáta potom poskytujú informácie o procesoch a faktoroch, ktoré ich ovplyvňujú. Nevýhodami zavádzania IoT do všetkých procesov sú bezpečnostné riziká a tiež ľudské hľadisko, kedy môžu byť zamestnanci nižšej kvalifikácie považovaní za nadbytočných alebo môže dochádzať k celkovému znižovaniu ľudského kapitálu vo výrobných procesoch a s tým spojené rozsiahle problémy.',
      description:
        'Našou filozofiou je zodpovedný návrh a zavádzanie inteligentných systémov s využitím moderných komunikačných prvkov IoT do procesov všade tam, kde sa významne pomôže identifikácií problémov, podporia sa inovácie, dochádza k lepšiemu využitiu zdrojov. Práve čo najlepšie využitie zdrojov zvyšuje ekológiu a to so zreteľom na zabezpečenie kľúčových informácií a človeka, pre ktorého majú tieto systémy slúžiť a zjednodušiť mu prácu nie naopak :). Sme schopný navrhovať sieťovú infraštruktúru pre malé a stredné podniky a zaistiť tým tepny a žily pre navzájom komunikujúce systémy. Okrem IoT sú v tomto prístupe viditeľné prvky konceptu moderného priemyslu „Industry 4.0“!',
      sections: [
        {
          name: 'Inteligentné systémy',
          description:
            'Inteligentné systémy navzájom komunikujúce a prepojené v tzv. „Internete vecí“ (IoT, Internet of Things) predstavujú revolúciu v komunikácií, priemysle, biznise a spoločnosti vôbec. Možnosť zapojiť obrovské množstvo senzorov, smart zariadení a vstavaných (embedded) systémov tvoriacich funkčné časti väčších celkov. Zapojením inteligentných systémov a využitie moderných komunikačných technológií prináša veľmi veľké množstvo výhod pri:',
          focus: [
            { name: 'riadení procesov' },
            { name: 'rozhodovaní' },
            { name: 'zisťovaní kritických miest' },
            { name: 'získavaní kľúčových dát s ich štatistickom spracovaní.' },
          ],
        },
        {
          name: 'Siete',
          description:
            'Siete sú tvorené dôležitými cestami pre sprostredkovanie komunikácie medzi ľuďmi, zariadeniami alebo medzi ľuďmi a zariadeniami navzájom. Ide o trochu abstraktný pojem, ktorý zahŕňa veľký súhrn zariadení a pravidiel riadiacich výmenu správ a informácií. Nepochybne sú siete tepnami a žilami firiem aj domácností. V súčasnosti s nástupom IoT do sietí pribúdajú milióny zariadení umožňujúce väčšiu kontrolu nad procesmi, ich sledovanie a štatistické vyhodnocovanie veľmi cenných až konkurenčných dát. Samozrejme aj obyčajný prístup do internetu je doménou návrhu a spravovania sietí. Siete sú ďaleko zložitejšie štruktúry než aby sa dali vyjadriť jednoduchou a bežnou predstavou o zdrojovom zariadení informácií a zariadení príjemcu týchto informácií. SIete sa podľa účelu líšia tvarom, veľkosťou a funkciou.',
          info: 'My sme schopní poskytnúť návrh, realizáciu a nastavenie efektívnych zapojení zariadení do sietí pre potreby výrobných podnikov, firiem, kancelárií aj domácností, so zameraním nielen pre prístup na internet, ale aj pre zložitejšie infraštruktúry potrebné pre správu smart zariadení, senzorov pre zber dôležitých dát a následné štatistické spracovanie, kde sa z dát môžu stať cenné informácie.',
        },
        {
          name: 'Komunikačné prvky',
          description:
            'Jednotlivé zariadenia sietí ako sú routre, switche, modemy, huby, úložiská dát, ale aj zariadenia odosielajúce dáta ako senzory, aktuátory ai. sú komunikačnými prvkami sietí. Tieto zariadenia bývajú veľmi sofistikované a napriek vysokej kvalite a spoľahlivosti týchto zariadení môže byť často potrebná ich výmena z dôvodu nedostatočnej funkcie či z dôvodov preprojektovania siete.\n\n' +
            'V prípade potreby zásahov do infraštruktúry siete poskytujeme:',
          focus: [
            { name: 'Predaj routrov, switchov, modemov, hubov a ďalších sieťových prvkov' },
            { name: 'Návrh a doporučenia pre budovanie senzorických a inteligentných sietí s potrebnými prvkami' },
            { name: 'Nastavenia nami dodaných zariadení na základe požiadavkou Vašej používanej siete, prípadne budovanej siete' },
          ],
        },
      ],
      en: {
        id: 'iot',
        name: 'IoT Riešenia',
        info: 'IoT je skratka pre tzv. „internet vecí“ z angl. „Internet of Thing“ predstavuje fyzickú a logickú sieť s prepojením miliónov zariadení, senzorov a pracovných staníc s jedinečnou identifikáciou podľa unikátnej IP adresy. IoT umožňuje výmenu obrovského množstva dát z ľubovoľných z ľubovoľných prvkov systémov. Vhodne spracované dáta potom poskytujú informácie o procesoch a faktoroch, ktoré ich ovplyvňujú. Nevýhodami zavádzania IoT do všetkých procesov sú bezpečnostné riziká a tiež ľudské hľadisko, kedy môžu byť zamestnanci nižšej kvalifikácie považovaní za nadbytočných alebo môže dochádzať k celkovému znižovaniu ľudského kapitálu vo výrobných procesoch a s tým spojené rozsiahle problémy.',
        description:
          'Našou filozofiou je zodpovedný návrh a zavádzanie inteligentných systémov s využitím moderných komunikačných prvkov IoT do procesov všade tam, kde sa významne pomôže identifikácií problémov, podporia sa inovácie, dochádza k lepšiemu využitiu zdrojov. Práve čo najlepšie využitie zdrojov zvyšuje ekológiu a to so zreteľom na zabezpečenie kľúčových informácií a človeka, pre ktorého majú tieto systémy slúžiť a zjednodušiť mu prácu nie naopak :). Sme schopný navrhovať sieťovú infraštruktúru pre malé a stredné podniky a zaistiť tým tepny a žily pre navzájom komunikujúce systémy. Okrem IoT sú v tomto prístupe viditeľné prvky konceptu moderného priemyslu „Industry 4.0“!',
        sections: [
          {
            name: 'Inteligentné systémy',
            description:
              'Inteligentné systémy navzájom komunikujúce a prepojené v tzv. „Internete vecí“ (IoT, Internet of Things) predstavujú revolúciu v komunikácií, priemysle, biznise a spoločnosti vôbec. Možnosť zapojiť obrovské množstvo senzorov, smart zariadení a vstavaných (embedded) systémov tvoriacich funkčné časti väčších celkov. Zapojením inteligentných systémov a využitie moderných komunikačných technológií prináša veľmi veľké množstvo výhod pri:',
            focus: [
              { name: 'riadení procesov' },
              { name: 'rozhodovaní' },
              { name: 'zisťovaní kritických miest' },
              { name: 'získavaní kľúčových dát s ich štatistickom spracovaní.' },
            ],
          },
          {
            name: 'Siete',
            description:
              'Siete sú tvorené dôležitými cestami pre sprostredkovanie komunikácie medzi ľuďmi, zariadeniami alebo medzi ľuďmi a zariadeniami navzájom. Ide o trochu abstraktný pojem, ktorý zahŕňa veľký súhrn zariadení a pravidiel riadiacich výmenu správ a informácií. Nepochybne sú siete tepnami a žilami firiem aj domácností. V súčasnosti s nástupom IoT do sietí pribúdajú milióny zariadení umožňujúce väčšiu kontrolu nad procesmi, ich sledovanie a štatistické vyhodnocovanie veľmi cenných až konkurenčných dát. Samozrejme aj obyčajný prístup do internetu je doménou návrhu a spravovania sietí. Siete sú ďaleko zložitejšie štruktúry než aby sa dali vyjadriť jednoduchou a bežnou predstavou o zdrojovom zariadení informácií a zariadení príjemcu týchto informácií. SIete sa podľa účelu líšia tvarom, veľkosťou a funkciou.',
            info: 'My sme schopní poskytnúť návrh, realizáciu a nastavenie efektívnych zapojení zariadení do sietí pre potreby výrobných podnikov, firiem, kancelárií aj domácností, so zameraním nielen pre prístup na internet, ale aj pre zložitejšie infraštruktúry potrebné pre správu smart zariadení, senzorov pre zber dôležitých dát a následné štatistické spracovanie, kde sa z dát môžu stať cenné informácie.',
          },
          {
            name: 'Komunikačné prvky',
            description:
              'Jednotlivé zariadenia sietí ako sú routre, switche, modemy, huby, úložiská dát, ale aj zariadenia odosielajúce dáta ako senzory, aktuátory ai. sú komunikačnými prvkami sietí. Tieto zariadenia bývajú veľmi sofistikované a napriek vysokej kvalite a spoľahlivosti týchto zariadení môže byť často potrebná ich výmena z dôvodu nedostatočnej funkcie či z dôvodov preprojektovania siete.\n\n' +
              'V prípade potreby zásahov do infraštruktúry siete poskytujeme:',
            focus: [
              { name: 'Predaj routrov, switchov, modemov, hubov a ďalších sieťových prvkov' },
              { name: 'Návrh a doporučenia pre budovanie senzorických a inteligentných sietí s potrebnými prvkami' },
              { name: 'Nastavenia nami dodaných zariadení na základe požiadavkou Vašej používanej siete, prípadne budovanej siete' },
            ],
          },
        ],
      },
    },
    {
      id: '3Dprint',
      name: '3D Tlač',
      description:
        'Čo 3D tlač predstavuje a prečo je taká dobrá? Získame niečo využívaním 3D popri masovej výrobe? Nie je to drahé, pomalé a neužitočné? Odpoveďou na tieto otázky sa Vás pokúsime presvedčiť o možnostiach a výhodách, ktoré technológie 3D tlače poskytujú a o nevyhnutnosti takéto zmeny v konkurenčnom prostredí zavádzať.\n\n' +
        'Začneme odborne 3D tlač je tzv. aditívnou výrobnou technológiou, čo znamená, že materiál použitý na výrobu objektov sa pridáva až do vytvorenia finálneho tvaru objektu. Materiál nie je zväčša potrebné odoberať, už z tohoto pohľadu vidíme ekonomický a ekologický prínos v minimalizácií odpadu. Ďalej spoločne pokračujme rýdzo prakticky… 3D tlač umožňuje preniesť zložité návrhy konštrukcií a následné testovanie na sofistikovaných výrobných linkách, s cieľom prípravy prototypov a ladeniu výroby bližšie k návrhárovi s lokálnou pôsobnosť napr. priamo vo výrobnom podniku. Veľmi efektívnym a rýchlym spôsobom možno testovať návrhy, zmeny vo výrobe, prototypy apod. na úrovni podniku. Výhodou je rýchla implementácia od fáze nápadu po príslušné grafické a technické vyjadrenie softwarovou cestou pomocou 3D CAD a následne veľmi presná realizácia myšlienky priamo do finálneho alebo testovacieho objektu realizovaného technológiou 3D tlače. Možnosti sú obrovské navyše v prípade 3D tlače platí, že zložitosť nezvyšuje cenu. Zmeny vo výrobe vyskúšané týmito technológiami sú veľmi šetrné ekonomicky a ekologicky. Nevyhnutne bude 3D tlač prechádzať naďalej rapídnym vývojom a bude podstatne meniť výrobnú sféru.',
      sections: [
        {
          name: 'Materiály a postupy',
          description:
            'Materiálov používaných pre 3D tlač existuje mnoho. My využívame tie najpoužívanejšie a pre potreby prototypovania alebo návrhu jednoduchých funkčných objektov ideálne polymérne materiály zaradené do skupiny termoplastov. Široká škála výhod tohto typu materiálov je daná jednoduchým spôsobom dávkovania do extrudérov 3D tlačiarní viď. obrázok pomocou vlákien navinutých na cievke viď. obrázok. Tento postup dáva pomenovanie celej technológií 3D tlačiarní, vlákna modelujú tvary depozíciou ich taveniny na podložku presným tlačovým zariadení s tryskou viď. obrázok, známa je skratka FDM (z angl. fused deposition modeling). Jednoducho možno tento postup prirovnať ku klasickej atramentovej tlačiarni s atramentom s tým rozdielom, že na získanie kvapalného média tavíme vlákno nad bod topenia a namiesto 2D obrázkov sa postupným nanášaním a tuhnutím po ochladení taveniny termoplastického materiálu získajú zložité 3D objekty. Výhody termoplastických materiálov sú… Detailný popis vlastností týchto vlákien a praktické tipy Vám radi poskytneme v sekcii Filamenty (pozri bočný panel vpravo). Druhým typom tlačiarní sú stereolitografické tlačiarne SLA (z angl. stereolitography) alebo principiálne podobné laserové tlačiarne SLS (z angl. selective laser sintering). Tieto tlačiarne potrebujú pre svoju činnosť opticky aktívne materiály – tekuté polymérne živice, ktoré sú vytvrdzované svetelným, UV alebo laserovým žiarením. Živice majú množstvo unikátnych vlastností pre samotnú tlač aj charakter výrobkov… . Detailný popis vlastností a druhov živíc pre 3D tlač je uvedený v sekcii Živice.\n\n' +
            'Dôležité pre kvalitnú tlač je výber vlákna resp. tekutej živice pri SLA tlačiarňach… , vlastnosti tlačiarne (rady pre výber tlačiarne) a parametre tlače, rozlíšenie...',
        },
        {
          name: '3D Prototyping',
          description:
            'Prevod Vašich ideí do výsledných kontúr objektov reálneho sveta je niekoľko stupňový proces. Začína samotnou predstavou o podobe predmetu a jeho vlastnostiach, toto je len prvá etapa tvorby. Druhou etapou je tvorba modelu tvarov a vlastností objektu definovaným modelom najčastejšie s využitím programov na základe CAD. Model v súboroch takýchto programov je následne prevedený na súbor zrozumiteľný pre 3D tlačiareň (napr. STL, 3MF atď.). Až po načítaní takéhoto súboru je 3D tlačiareň schopná previesť nápad k „životu“. Celý proces predstavuje tzv. prototyping. Pre užívateľov či už ide o hobby alebo profesionálnych návrhárov je najťažší prechod od prvej etapy návrhu k modelu realizovanom vo vhodnom programe. Veľmi radi pomôžeme či už cennými radami alebo konkrétnymi vyhotoveniami modelov. Našim hlavným zameraním je však použitie tejto v mnohých aspektoch skvelej technológie v širších projektoch, kde sú možnosti individuálneho návrhu na mieru nedoceniteľné. V mnohých projektoch IoT, automatizácií, riadení, analýze kľúčových faktorov apod. sú možnosti špeciálneho návrhu na mieru natoľko ekonomické a výhodné, že je 3D prototyping obrovskou výhodou.',
        },
      ],
      en: {
        id: '3Dprint',
        name: '3D Tlač',
        description:
          'Čo 3D tlač predstavuje a prečo je taká dobrá? Získame niečo využívaním 3D popri masovej výrobe? Nie je to drahé, pomalé a neužitočné? Odpoveďou na tieto otázky sa Vás pokúsime presvedčiť o možnostiach a výhodách, ktoré technológie 3D tlače poskytujú a o nevyhnutnosti takéto zmeny v konkurenčnom prostredí zavádzať.\n\n' +
          'Začneme odborne 3D tlač je tzv. aditívnou výrobnou technológiou, čo znamená, že materiál použitý na výrobu objektov sa pridáva až do vytvorenia finálneho tvaru objektu. Materiál nie je zväčša potrebné odoberať, už z tohoto pohľadu vidíme ekonomický a ekologický prínos v minimalizácií odpadu. Ďalej spoločne pokračujme rýdzo prakticky… 3D tlač umožňuje preniesť zložité návrhy konštrukcií a následné testovanie na sofistikovaných výrobných linkách, s cieľom prípravy prototypov a ladeniu výroby bližšie k návrhárovi s lokálnou pôsobnosť napr. priamo vo výrobnom podniku. Veľmi efektívnym a rýchlym spôsobom možno testovať návrhy, zmeny vo výrobe, prototypy apod. na úrovni podniku. Výhodou je rýchla implementácia od fáze nápadu po príslušné grafické a technické vyjadrenie softwarovou cestou pomocou 3D CAD a následne veľmi presná realizácia myšlienky priamo do finálneho alebo testovacieho objektu realizovaného technológiou 3D tlače. Možnosti sú obrovské navyše v prípade 3D tlače platí, že zložitosť nezvyšuje cenu. Zmeny vo výrobe vyskúšané týmito technológiami sú veľmi šetrné ekonomicky a ekologicky. Nevyhnutne bude 3D tlač prechádzať naďalej rapídnym vývojom a bude podstatne meniť výrobnú sféru.',
        sections: [
          {
            name: 'Materiály a postupy',
            description:
              'Materiálov používaných pre 3D tlač existuje mnoho. My využívame tie najpoužívanejšie a pre potreby prototypovania alebo návrhu jednoduchých funkčných objektov ideálne polymérne materiály zaradené do skupiny termoplastov. Široká škála výhod tohto typu materiálov je daná jednoduchým spôsobom dávkovania do extrudérov 3D tlačiarní viď. obrázok pomocou vlákien navinutých na cievke viď. obrázok. Tento postup dáva pomenovanie celej technológií 3D tlačiarní, vlákna modelujú tvary depozíciou ich taveniny na podložku presným tlačovým zariadení s tryskou viď. obrázok, známa je skratka FDM (z angl. fused deposition modeling). Jednoducho možno tento postup prirovnať ku klasickej atramentovej tlačiarni s atramentom s tým rozdielom, že na získanie kvapalného média tavíme vlákno nad bod topenia a namiesto 2D obrázkov sa postupným nanášaním a tuhnutím po ochladení taveniny termoplastického materiálu získajú zložité 3D objekty. Výhody termoplastických materiálov sú… Detailný popis vlastností týchto vlákien a praktické tipy Vám radi poskytneme v sekcii Filamenty (pozri bočný panel vpravo). Druhým typom tlačiarní sú stereolitografické tlačiarne SLA (z angl. stereolitography) alebo principiálne podobné laserové tlačiarne SLS (z angl. selective laser sintering). Tieto tlačiarne potrebujú pre svoju činnosť opticky aktívne materiály – tekuté polymérne živice, ktoré sú vytvrdzované svetelným, UV alebo laserovým žiarením. Živice majú množstvo unikátnych vlastností pre samotnú tlač aj charakter výrobkov… . Detailný popis vlastností a druhov živíc pre 3D tlač je uvedený v sekcii Živice.\n\n' +
              'Dôležité pre kvalitnú tlač je výber vlákna resp. tekutej živice pri SLA tlačiarňach… , vlastnosti tlačiarne (rady pre výber tlačiarne) a parametre tlače, rozlíšenie...',
          },
          {
            name: '3D Prototyping',
            description:
              'Prevod Vašich ideí do výsledných kontúr objektov reálneho sveta je niekoľko stupňový proces. Začína samotnou predstavou o podobe predmetu a jeho vlastnostiach, toto je len prvá etapa tvorby. Druhou etapou je tvorba modelu tvarov a vlastností objektu definovaným modelom najčastejšie s využitím programov na základe CAD. Model v súboroch takýchto programov je následne prevedený na súbor zrozumiteľný pre 3D tlačiareň (napr. STL, 3MF atď.). Až po načítaní takéhoto súboru je 3D tlačiareň schopná previesť nápad k „životu“. Celý proces predstavuje tzv. prototyping. Pre užívateľov či už ide o hobby alebo profesionálnych návrhárov je najťažší prechod od prvej etapy návrhu k modelu realizovanom vo vhodnom programe. Veľmi radi pomôžeme či už cennými radami alebo konkrétnymi vyhotoveniami modelov. Našim hlavným zameraním je však použitie tejto v mnohých aspektoch skvelej technológie v širších projektoch, kde sú možnosti individuálneho návrhu na mieru nedoceniteľné. V mnohých projektoch IoT, automatizácií, riadení, analýze kľúčových faktorov apod. sú možnosti špeciálneho návrhu na mieru natoľko ekonomické a výhodné, že je 3D prototyping obrovskou výhodou.',
          },
        ],
      },
    },
    {
      id: 'chemistry',
      name: 'Technická chémia',
      info: 'Pod pojmom technická chémia sa rozumie oblasť tak široká, že jej úplné obsiahnutie v jedinej spoločnosti s danou ľudskou kapacitou nie je možné jednoducho preto, že by sme museli zamestnať milióny odborníkov z rôznych odvetví a krajín to samozrejme nejde :-). Účelné je zamerať sa na tie najbežnejšie potreby, ktoré je možné prostredníctvom technickej chémie riešiť a naopak špecializovať sa na potreby zákazníkov pre potreby ich odvetvia v prostredí podnikania. Pod technickú chémiu možno zahrnúť chémiu materiálov – odvetvia vyrábajúce, spracovávajúce a upravujúce rôzne materiály ako kovy, keramiku, sklá, plasty až po moderné kompozitné materiály. S materiálmi veľmi súvisí problematika ich spájania tj. spôsoby tvorby adhéznych a kohéznych spojov (lepenia). Často býva dôležité ich čistenie, kde ide o efektivitu a rýchlosť týchto procesov pre následné úpravy materiálov alebo finálnych výrobkov, kde sa v súčasnosti veľmi vyzdvihuje ekologické hľadisko. Koniec životného cyklu výrobku je charakteristický snahou o ekologickú likvidáciu a dosiahnutie, čo najvyššieho stupňa recyklácie. Osobitne zaraďujeme do technickej chémie oblasti, ktoré sú využívané v rámci našej činnosti a poradenstva. Ide o chémiu moderných a rastúcich oblastí 3D tlače a chémiu pre elektroniku, kde sa neustále objavujú nové trendy.',
      description:
        'Možnosti technickej chémie sú neobmedzené a neustále sa zdokonaľujú postupy, ktoré možno priamo aplikovať vo výrobe, spracovaní, ochranných opatreniach pre rôzne materiály a výrobky z nich. My využívame naše skúsenosti pre návrh rôznych postupov s využitím ekonomicky a ekologicky vhodnej chémie, venujeme sa oblastiam:',
      focus: [
        { name: 'antikoróznych opatrení kovov a plastov' },
        { name: 'povrchovým úpravám kovov, plastov a kompozitov pre ochranné, funkčné a estetické účely' },
        { name: 'čisteniu povrchov všetkých druhov materiálov a hľadanie riešení prípadných problémov spôsobených chemickým znečistením' },
        { name: 'lepeniu povrchov rôznych druhov materiálov' },
        { name: 'rozpúšťaniu, rozkladu a recyklácií materiálových odpadov' },
        {
          name: 'výskumu zložitejších problémov, ktoré sú súčasťou výrobných, spracovateľských alebo funkčných častí životného cyklu výrobkov s nutnosťou hlbších analýz a experimentov',
        },
        { name: 'elektrochemickým metódam v rámci úprav materiálov alebo elektroniky' },
        { name: 'špeciálnym prípravkom na starostlivosť o funkčné oblečenie' },
        { name: 'ošetrovaniu optických komponentov a ich údržba' },
      ],
      sections: [
        {
          name: 'Chémia materíalov',
          description:
            'Elektronika a mikroelektronika sú extrémne rýchlo rastúce odbory. Vo výrobách a servise elektroniky sa rieši úprava elektrických, materiálových, tepelných, optických atď. vlastností používaním širokej škály chemických prípravkov. V súčasnosti sa dôraz kladie najmä na kvalitu, cenu a ekológiu takýchto prostriedkov. Požiadavky si veľmi často odporujú preto je nutné dobre zvážiť, ktorá s možností je najvýhodnejšia pre danú aplikáciu.',
        },
        {
          name: 'Chémia pre elektroniku',
          description:
            'Elektronika a mikroelektronika sú extrémne rýchlo rastúce odbory. Vo výrobách a servise elektroniky sa rieši úprava elektrických, materiálových, tepelných, optických atď. vlastností používaním širokej škály chemických prípravkov. V súčasnosti sa dôraz kladie najmä na kvalitu, cenu a ekológiu takýchto prostriedkov. Požiadavky si veľmi často odporujú preto je nutné dobre zvážiť, ktorá s možností je najvýhodnejšia pre danú aplikáciu.',
        },
      ],
      en: {
        id: 'chemistry',
        name: 'Technická chémia',
        info: 'Pod pojmom technická chémia sa rozumie oblasť tak široká, že jej úplné obsiahnutie v jedinej spoločnosti s danou ľudskou kapacitou nie je možné jednoducho preto, že by sme museli zamestnať milióny odborníkov z rôznych odvetví a krajín to samozrejme nejde :-). Účelné je zamerať sa na tie najbežnejšie potreby, ktoré je možné prostredníctvom technickej chémie riešiť a naopak špecializovať sa na potreby zákazníkov pre potreby ich odvetvia v prostredí podnikania. Pod technickú chémiu možno zahrnúť chémiu materiálov – odvetvia vyrábajúce, spracovávajúce a upravujúce rôzne materiály ako kovy, keramiku, sklá, plasty až po moderné kompozitné materiály. S materiálmi veľmi súvisí problematika ich spájania tj. spôsoby tvorby adhéznych a kohéznych spojov (lepenia). Často býva dôležité ich čistenie, kde ide o efektivitu a rýchlosť týchto procesov pre následné úpravy materiálov alebo finálnych výrobkov, kde sa v súčasnosti veľmi vyzdvihuje ekologické hľadisko. Koniec životného cyklu výrobku je charakteristický snahou o ekologickú likvidáciu a dosiahnutie, čo najvyššieho stupňa recyklácie. Osobitne zaraďujeme do technickej chémie oblasti, ktoré sú využívané v rámci našej činnosti a poradenstva. Ide o chémiu moderných a rastúcich oblastí 3D tlače a chémiu pre elektroniku, kde sa neustále objavujú nové trendy.',
        description:
          'Možnosti technickej chémie sú neobmedzené a neustále sa zdokonaľujú postupy, ktoré možno priamo aplikovať vo výrobe, spracovaní, ochranných opatreniach pre rôzne materiály a výrobky z nich. My využívame naše skúsenosti pre návrh rôznych postupov s využitím ekonomicky a ekologicky vhodnej chémie, venujeme sa oblastiam:',
        focus: [
          { name: 'antikoróznych opatrení kovov a plastov' },
          { name: 'povrchovým úpravám kovov, plastov a kompozitov pre ochranné, funkčné a estetické účely' },
          { name: 'čisteniu povrchov všetkých druhov materiálov a hľadanie riešení prípadných problémov spôsobených chemickým znečistením' },
          { name: 'lepeniu povrchov rôznych druhov materiálov' },
          { name: 'rozpúšťaniu, rozkladu a recyklácií materiálových odpadov' },
          {
            name: 'výskumu zložitejších problémov, ktoré sú súčasťou výrobných, spracovateľských alebo funkčných častí životného cyklu výrobkov s nutnosťou hlbších analýz a experimentov',
          },
          { name: 'elektrochemickým metódam v rámci úprav materiálov alebo elektroniky' },
          { name: 'špeciálnym prípravkom na starostlivosť o funkčné oblečenie' },
          { name: 'ošetrovaniu optických komponentov a ich údržba' },
        ],
        sections: [
          {
            name: 'Chémia materíalov',
            description:
              'Elektronika a mikroelektronika sú extrémne rýchlo rastúce odbory. Vo výrobách a servise elektroniky sa rieši úprava elektrických, materiálových, tepelných, optických atď. vlastností používaním širokej škály chemických prípravkov. V súčasnosti sa dôraz kladie najmä na kvalitu, cenu a ekológiu takýchto prostriedkov. Požiadavky si veľmi často odporujú preto je nutné dobre zvážiť, ktorá s možností je najvýhodnejšia pre danú aplikáciu.',
          },
          {
            name: 'Chémia pre elektroniku',
            description:
              'Elektronika a mikroelektronika sú extrémne rýchlo rastúce odbory. Vo výrobách a servise elektroniky sa rieši úprava elektrických, materiálových, tepelných, optických atď. vlastností používaním širokej škály chemických prípravkov. V súčasnosti sa dôraz kladie najmä na kvalitu, cenu a ekológiu takýchto prostriedkov. Požiadavky si veľmi často odporujú preto je nutné dobre zvážiť, ktorá s možností je najvýhodnejšia pre danú aplikáciu.',
          },
        ],
      },
    },
  ],
  projects: {
    readMore: 'Read more',
    en: {
      readMore: 'Read more',
    },
  },
  ourTeam: [
    // id is used for photo
    {
      id: 'lv',
      name: 'Lukáš Višňovec',
      position: 'Market Analyst',
      en: {
        position: 'Market Analyst',
      },
    },
    {
      id: 'rv',
      name: 'Robert Višňovec',
      position: 'Market Analyst, Developer',
      en: {
        position: 'Market Analyst, Developer',
      },
    },
    {
      id: 'jm',
      name: 'Jozef Minda',
      position: 'Project Manager, Developer',
      en: {
        position: 'Project Manager, Developer',
      },
    },
    {
      id: 'tp',
      name: 'Tomáš Porubský',
      position: 'Project Manager',
      en: {
        position: 'Project Manager',
      },
    },
    {
      id: 'dl',
      name: 'Dominik Láclavík',
      position: 'Developer',
      en: {
        position: 'Developer',
      },
    },
  ],
  contact: {
    title: 'Kontaktujte nás',
    interested: 'Zaujali sme Vás?',
    form: {
      title: 'Zanechajte nám správu a my sa vám ozveme späť!',
      fields: [
        { id: 'fullName', placeholder: 'Meno a priezvisko', required: true },
        { id: 'company', placeholder: 'Spoločnosť' },
        { id: 'email', placeholder: 'Váš e-mail', required: true },
        { id: 'subject', placeholder: 'Predmet správy' },
      ],
      message: 'Váš dotaz',
      button: 'Odoslať',
      sent: 'Ďakujeme za Váš dotaz. Vaším dotazom sa budeme zaoberať a odpoveď zašleme na Vami uvedenú e-mailovú adresu.',
      failed: 'Niečo sa pokazilo, skúste to prosím znovu',
    },
    company: {
      title: 'Kontaktné informácie',
      list: [
        {
          title: 'Kde nás nájdete',
          subTitle: 'Prinet s.r.o',
          content: 'Novákova 555/7\nMartin\n036 01\n\nIČ: 21354434\nDIČ: 63231243',
        },
        {
          title: 'E-mail',
          content: 'info@prinet.sk',
        },
        {
          title: 'Telefón',
          content: 'Mobil: (+421) 948 917 001‬',
        },
      ],
    },
    en: {
      title: 'Kontaktujte nás',
      interested: 'Zaujali sme Vás?',
      form: {
        title: 'Zanechajte nám správu a my sa vám ozveme späť!',
        fields: [
          { id: 'fullName', placeholder: 'Meno a priezvisko', required: true },
          { id: 'company', placeholder: 'Spoločnosť' },
          { id: 'email', placeholder: 'Váš e-mail', required: true },
          { id: 'subject', placeholder: 'Predmet správy' },
        ],
        message: 'Váš dotaz',
        button: 'Odoslať',
        sent: 'Ďakujeme za Váš dotaz. Vaším dotazom sa budeme zaoberať a odpoveď zašleme na Vami uvedenú e-mailovú adresu.',
        failed: 'Niečo sa pokazilo, skúste to prosím znovu',
      },
      company: {
        title: 'Kontaktné informácie',
        list: [
          {
            title: 'Kde nás nájdete',
            subTitle: 'Prinet s.r.o',
            content: 'Novákova 555/7\nMartin\n036 01\n\nIČ: 21354434\nDIČ: 63231243',
          },
          {
            title: 'E-mail',
            content: 'info@prinet.sk',
          },
          {
            title: 'Telefón',
            content: 'Mobil: (+421) 948 917 001‬',
          },
        ],
      },
    },
  },
  company: {
    name: 'Prinet',
    footerExtension: 'All rights reserved',
    companyHeadquarters: {
      defaultZoom: 17,
      defaultCenter: {
        lat: 49.06442,
        lng: 18.91686,
      },
    },
    en: {
      name: 'Prinet',
      footerExtension: 'All rights reserved',
      companyHeadquarters: {
        defaultZoom: 17,
        defaultCenter: {
          lat: 49.06442,
          lng: 18.91686,
        },
      },
    },
  },
  blog: {
    title: 'Blog',
    tagCloud: 'Tag Cloud',
    tags: 'Tags',
    comments: 'Comments',
    posts: 'Posts',
    en: {
      title: 'Blog',
      tagCloud: 'Tag Cloud',
      tags: 'Tags',
      comments: 'Comments',
      posts: 'Posts',
    },
  },
  utils: {
    searchFor: 'Search for',
    go: 'Go',
    next: 'Next',
    prev: 'Previous',
    readMore: 'Read more',
    page: 'Page',
    of: 'of',
    yourNick: 'Your nick',
    yourComment: 'Your comment',
    addComment: 'Add comment',
    comment: 'Comments',
    en: {
      searchFor: 'Search for',
      go: 'Go',
      next: 'Next',
      prev: 'Previous',
      readMore: 'Read more',
      page: 'Page',
      of: 'of',
      yourNick: 'Your nick',
      yourComment: 'Your comment',
      addComment: 'Add comment',
      comment: 'Comments',
    },
  },
  googleMapApiKey: 'AIzaSyDTtTdd5N6SfMv52GpMlDAnX2Oz7_g8TWk',
  googleAnalyticsKey: 'UA-162809915-1',
  pages: {
    aboutUs: {
      title: 'SVET PODĽA NÁS',
      body: [
        {
          text: 'Náš život je o moderných technológiách, dostali sa všade, pre business je nevyhnutné držať sa trendov byť konkurencieschopný, zvyšovať efektivitu, zlepšovať ekonomiku a ekológiu, zavádzať automatizácia systémov, IT solutions apod. Tieto termíny znejú ako klišé, ale sú a naďalej budú vyzdvihované, je to pochopiteľné veď problémy vždy motivujú k nájdeniu riešenia. V každom období sa vzniknú strategické prístupy, ktoré sú zdrojom inovácií a spoločenských zmien. Súčasnosť už dlhšiu dobu prináša koncepty ako priemysel 3.0, vznik veľmi prepojených inteligentných systémov v globálnej sieti IoT, lacné a veľmi dostupné aditívne technológie pre výrobu na mieru v podobe 3D tlače. Ide o fascinujúce koncepty s obrovským potenciálom zlepšovať naše okolie, ale samozrejme to prináša aj riziká v bezpečnosti, značnú komplikovanosť, požiadavky na vzdelávanie a ďalšie investičné a prevádzkové náklady. Našim cieľom je podieľať sa na týchto zmenách tak, aby sme prinášali zjednodušenia, nekomplikovali už tak technicky náročné prostredie aby bol vždy v centre človek a jeho šťastie, potom má zmysel hovoriť o zjednodušení života. Každého, kto tento dôležitý rozmer vidí považujeme za partnera a sme pripravení spolupracovať, budeme veľmi radi keď sa pri tom sami mnohému naučíme, pretože možnosti sú v tomto smere nevyčerpateľné.',
        },
        {
          title: 'Kto sme?',
          text: 'Predovšetkým partia ľudí, ktorí sledovali svet okolo seba, zaujímali sa a boli na všetko zvedaví. Zvedavosť sme nenapĺňali len školou, ale aj svojimi vlastnými projektami a plánmi. Keď cestujete po svete nechajte sa inšpirovať, keď Vám niečo chýba pýtajte sa priateľov a zistíte, že toho viete urobiť mnoho pre spokojnosť seba a iných ľudí…\n\n',
        },
        {
          title: 'Spoločnosť Prinet…',
          text: 'sa snaží využiť skvelé spojenie veľmi dostupnej výrobnej technológie prototypov 3D tlače, znalosti s odvetvia chémie dôležité pre riešenie priemyselných a materiálových problémov s možnosťou zapojenia IoT infraštruktúry pri nastavovaní procesov v podnikoch.\n\n',
        },
        {
          title: 'Ponúkame spoluprácu: ',
          list: [
            {
              icon: 'model',
              text: 'Modelovane vytváraní jednoduchých prototypov súčiastok, nástrojov alebo úžitkových predmetov aditívnym výrobným postupom z termoplastických materiálov a ich povrchové úpravy',
            },
            {
              icon: 'simulation',
              text: 'Simulácie materiálových a iných problémov, ktoré je možné vykonať za použitím modelov, prípadne je možné uskutočniť modelovú situáciu',
            },
            {
              icon: 'chemistry',
              text: 'Poradenstvo a poskytovanie chemických prostriedkov vhodných pre konkrétne priemyselné a domáce využitie alebo pre odvetvia elektroniky a 3D tlače',
            },
            {
              icon: 'hardware',
              text: 'Navrhovanie softwarových a hardwarových možností pre sledovanie, optimalizáciu a riadenie procesov s možnosťou pomoci pri vyhodnocovaní získavaných dát a minimálnou záťažou pre Vás a Vašich zamestnancov alebo blízkych',
            },
            {
              icon: 'intelligent',
              text: 'Nastavovanie a navrhovanie sieťovej infraštruktúry pre zapojenie pracovných jednotiek a senzorických sietí do internetu (malé a stredné kancelárske siete, IoT elementy apod.',
            },
          ],
        },
      ],
      en: {
        title: 'SVET PODĽA NÁS',
        body: [
          {
            text: 'Náš život je o moderných technológiách, dostali sa všade, pre business je nevyhnutné držať sa trendov byť konkurencieschopný, zvyšovať efektivitu, zlepšovať ekonomiku a ekológiu, zavádzať automatizácia systémov, IT solutions apod. Tieto termíny znejú ako klišé, ale sú a naďalej budú vyzdvihované, je to pochopiteľné veď problémy vždy motivujú k nájdeniu riešenia. V každom období sa vzniknú strategické prístupy, ktoré sú zdrojom inovácií a spoločenských zmien. Súčasnosť už dlhšiu dobu prináša koncepty ako priemysel 3.0, vznik veľmi prepojených inteligentných systémov v globálnej sieti IoT, lacné a veľmi dostupné aditívne technológie pre výrobu na mieru v podobe 3D tlače. Ide o fascinujúce koncepty s obrovským potenciálom zlepšovať naše okolie, ale samozrejme to prináša aj riziká v bezpečnosti, značnú komplikovanosť, požiadavky na vzdelávanie a ďalšie investičné a prevádzkové náklady. Našim cieľom je podieľať sa na týchto zmenách tak, aby sme prinášali zjednodušenia, nekomplikovali už tak technicky náročné prostredie aby bol vždy v centre človek a jeho šťastie, potom má zmysel hovoriť o zjednodušení života. Každého, kto tento dôležitý rozmer vidí považujeme za partnera a sme pripravení spolupracovať, budeme veľmi radi keď sa pri tom sami mnohému naučíme, pretože možnosti sú v tomto smere nevyčerpateľné.',
          },
          {
            title: 'Kto sme?',
            text: 'Predovšetkým partia ľudí, ktorí sledovali svet okolo seba, zaujímali sa a boli na všetko zvedaví. Zvedavosť sme nenapĺňali len školou, ale aj svojimi vlastnými projektami a plánmi. Keď cestujete po svete nechajte sa inšpirovať, keď Vám niečo chýba pýtajte sa priateľov a zistíte, že toho viete urobiť mnoho pre spokojnosť seba a iných ľudí…\n\n',
          },
          {
            title: 'Spoločnosť Prinet…',
            text: 'sa snaží využiť skvelé spojenie veľmi dostupnej výrobnej technológie prototypov 3D tlače, znalosti s odvetvia chémie dôležité pre riešenie priemyselných a materiálových problémov s možnosťou zapojenia IoT infraštruktúry pri nastavovaní procesov v podnikoch.\n\n',
          },
          {
            title: 'Ponúkame spoluprácu: ',
            list: [
              {
                icon: 'model',
                text: 'Modelovane vytváraní jednoduchých prototypov súčiastok, nástrojov alebo úžitkových predmetov aditívnym výrobným postupom z termoplastických materiálov a ich povrchové úpravy',
              },
              {
                icon: 'simulation',
                text: 'Simulácie materiálových a iných problémov, ktoré je možné vykonať za použitím modelov, prípadne je možné uskutočniť modelovú situáciu',
              },
              {
                icon: 'chemistry',
                text: 'Poradenstvo a poskytovanie chemických prostriedkov vhodných pre konkrétne priemyselné a domáce využitie alebo pre odvetvia elektroniky a 3D tlače',
              },
              {
                icon: 'hardware',
                text: 'Navrhovanie softwarových a hardwarových možností pre sledovanie, optimalizáciu a riadenie procesov s možnosťou pomoci pri vyhodnocovaní získavaných dát a minimálnou záťažou pre Vás a Vašich zamestnancov alebo blízkych',
              },
              {
                icon: 'intelligent',
                text: 'Nastavovanie a navrhovanie sieťovej infraštruktúry pre zapojenie pracovných jednotiek a senzorických sietí do internetu (malé a stredné kancelárske siete, IoT elementy apod.',
              },
            ],
          },
        ],
      },
    },
    [PAGES.print]: {
      title: '3D Tlač',
      body: [
        {
          text: 'Čo 3D tlač predstavuje a prečo je taká dobrá? Získame niečo využívaním 3D popri masovej výrobe? Nie je to drahé, pomalé a neužitočné? Odpoveďou na tieto otázky sa Vás pokúsime presvedčiť o možnostiach a výhodách, ktoré technológie 3D tlače poskytujú a o nevyhnutnosti takéto zmeny v konkurenčnom prostredí zavádzať.',
        },
        {
          text: 'Začneme odborne 3D tlač je tzv. aditívnou výrobnou technológiou, čo znamená, že materiál použitý na výrobu objektov sa pridáva až do vytvorenia finálneho tvaru objektu. Materiál nie je zväčša potrebné odoberať, už z tohoto pohľadu vidíme ekonomický a ekologický prínos v minimalizácií odpadu. Ďalej spoločne pokračujme rýdzo prakticky… 3D tlač umožňuje preniesť zložité návrhy konštrukcií a následné testovanie na sofistikovaných výrobných linkách, s cieľom prípravy prototypov a ladeniu výroby bližšie k návrhárovi s lokálnou pôsobnosť napr. priamo vo výrobnom podniku. Veľmi efektívnym a rýchlym spôsobom možno testovať návrhy, zmeny vo výrobe, prototypy apod. na úrovni podniku. Výhodou je rýchla implementácia od fáze nápadu po príslušné grafické a technické vyjadrenie softwarovou cestou pomocou 3D CAD a následne veľmi presná realizácia myšlienky priamo do finálneho alebo testovacieho objektu realizovaného technológiou 3D tlače. Možnosti sú obrovské navyše v prípade 3D tlače platí, že zložitosť nezvyšuje cenu. Zmeny vo výrobe vyskúšané týmito technológiami sú veľmi šetrné ekonomicky a ekologicky. Nevyhnutne bude 3D tlač prechádzať naďalej rapídnym vývojom a bude podstatne meniť výrobnú sféru.',
        },
      ],
      links: [
        { icon: 'print', title: 'Materiály a postupy', page: PAGES.materialsAndProcedures },
        { icon: 'model', title: '3D Prototyping', page: PAGES.printPrototyping },
        { icon: 'info', title: 'Rady pre výber tlačiarne', page: PAGES.printerSelectionTips },
      ],
      en: {
        title: '3D Tlač',
        body: [
          {
            text: 'Čo 3D tlač predstavuje a prečo je taká dobrá? Získame niečo využívaním 3D popri masovej výrobe? Nie je to drahé, pomalé a neužitočné? Odpoveďou na tieto otázky sa Vás pokúsime presvedčiť o možnostiach a výhodách, ktoré technológie 3D tlače poskytujú a o nevyhnutnosti takéto zmeny v konkurenčnom prostredí zavádzať.',
          },
          {
            text: 'Začneme odborne 3D tlač je tzv. aditívnou výrobnou technológiou, čo znamená, že materiál použitý na výrobu objektov sa pridáva až do vytvorenia finálneho tvaru objektu. Materiál nie je zväčša potrebné odoberať, už z tohoto pohľadu vidíme ekonomický a ekologický prínos v minimalizácií odpadu. Ďalej spoločne pokračujme rýdzo prakticky… 3D tlač umožňuje preniesť zložité návrhy konštrukcií a následné testovanie na sofistikovaných výrobných linkách, s cieľom prípravy prototypov a ladeniu výroby bližšie k návrhárovi s lokálnou pôsobnosť napr. priamo vo výrobnom podniku. Veľmi efektívnym a rýchlym spôsobom možno testovať návrhy, zmeny vo výrobe, prototypy apod. na úrovni podniku. Výhodou je rýchla implementácia od fáze nápadu po príslušné grafické a technické vyjadrenie softwarovou cestou pomocou 3D CAD a následne veľmi presná realizácia myšlienky priamo do finálneho alebo testovacieho objektu realizovaného technológiou 3D tlače. Možnosti sú obrovské navyše v prípade 3D tlače platí, že zložitosť nezvyšuje cenu. Zmeny vo výrobe vyskúšané týmito technológiami sú veľmi šetrné ekonomicky a ekologicky. Nevyhnutne bude 3D tlač prechádzať naďalej rapídnym vývojom a bude podstatne meniť výrobnú sféru.',
          },
        ],
        links: [
          { icon: 'print', title: 'Materiály a postupy', page: PAGES.materialsAndProcedures },
          { icon: 'model', title: '3D Prototyping', page: PAGES.printPrototyping },
          { icon: 'info', title: 'Rady pre výber tlačiarne', page: PAGES.printerSelectionTips },
        ],
      },
    },
    [PAGES.materialsAndProcedures]: {
      title: 'Materiály a postupy',
      body: [
        {
          text: 'Materiálov používaných pre 3D tlač existuje mnoho. My využívame tie najpoužívanejšie a pre potreby prototypovania alebo návrhu jednoduchých funkčných objektov ideálne polymérne materiály zaradené do skupiny termoplastov. Široká škála výhod tohto typu materiálov je daná jednoduchým spôsobom dávkovania do extrudérov 3D tlačiarní viď. obrázok pomocou vlákien navinutých na cievke viď. obrázok. Tento postup dáva pomenovanie celej technológií 3D tlačiarní, vlákna modelujú tvary depozíciou ich taveniny na podložku presným tlačovým zariadení s tryskou viď. obrázok, známa je skratka FDM (z angl. fused deposition modeling). Jednoducho možno tento postup prirovnať ku klasickej atramentovej tlačiarni s atramentom s tým rozdielom, že na získanie kvapalného média tavíme vlákno nad bod topenia a namiesto 2D obrázkov sa postupným nanášaním a tuhnutím po ochladení taveniny termoplastického materiálu získajú zložité 3D objekty. Výhody termoplastických materiálov sú… Detailný popis vlastností týchto vlákien a praktické tipy Vám radi poskytneme v sekcii Filamenty (pozri bočný panel vpravo). Druhým typom tlačiarní sú stereolitografické tlačiarne SLA (z angl. stereolitography) alebo principiálne podobné laserové tlačiarne SLS (z angl. selective laser sintering). Tieto tlačiarne potrebujú pre svoju činnosť opticky aktívne materiály – tekuté polymérne živice, ktoré sú vytvrdzované svetelným, UV alebo laserovým žiarením. Živice majú množstvo unikátnych vlastností pre samotnú tlač aj charakter výrobkov… . Detailný popis vlastností a druhov živíc pre 3D tlač je uvedený v sekcii Živice.',
          links: [
            { text: 'Filamenty', page: PAGES.filaments },
            { text: 'Živice', page: PAGES.materialsAndProcedures },
          ],
        },
        {
          text: 'Dôležité pre kvalitnú tlač je výber vlákna resp. tekutej živice pri SLA tlačiarňach… , vlastnosti tlačiarne (rady pre výber tlačiarne) a parametre tlače, rozlíšenie…',
          links: [{ text: 'rady pre výber tlačiarne', page: PAGES.printerSelectionTips }],
        },
      ],
      subLinks: {
        title: 'Materíály',
        links: [
          { text: 'PLA', page: PAGES.materialPLA },
          { text: 'PET', page: PAGES.materialPET },
          { text: 'PETG', page: PAGES.materialPETG },
          { text: 'ABS', page: PAGES.materialABS },
          { text: 'TPE', page: PAGES.materialTPE },
          { text: 'Polyamid', page: PAGES.materialPolyamid },
        ],
      },
      en: {
        title: 'Materiály a postupy',
        body: [
          {
            text: 'Materiálov používaných pre 3D tlač existuje mnoho. My využívame tie najpoužívanejšie a pre potreby prototypovania alebo návrhu jednoduchých funkčných objektov ideálne polymérne materiály zaradené do skupiny termoplastov. Široká škála výhod tohto typu materiálov je daná jednoduchým spôsobom dávkovania do extrudérov 3D tlačiarní viď. obrázok pomocou vlákien navinutých na cievke viď. obrázok. Tento postup dáva pomenovanie celej technológií 3D tlačiarní, vlákna modelujú tvary depozíciou ich taveniny na podložku presným tlačovým zariadení s tryskou viď. obrázok, známa je skratka FDM (z angl. fused deposition modeling). Jednoducho možno tento postup prirovnať ku klasickej atramentovej tlačiarni s atramentom s tým rozdielom, že na získanie kvapalného média tavíme vlákno nad bod topenia a namiesto 2D obrázkov sa postupným nanášaním a tuhnutím po ochladení taveniny termoplastického materiálu získajú zložité 3D objekty. Výhody termoplastických materiálov sú… Detailný popis vlastností týchto vlákien a praktické tipy Vám radi poskytneme v sekcii Filamenty (pozri bočný panel vpravo). Druhým typom tlačiarní sú stereolitografické tlačiarne SLA (z angl. stereolitography) alebo principiálne podobné laserové tlačiarne SLS (z angl. selective laser sintering). Tieto tlačiarne potrebujú pre svoju činnosť opticky aktívne materiály – tekuté polymérne živice, ktoré sú vytvrdzované svetelným, UV alebo laserovým žiarením. Živice majú množstvo unikátnych vlastností pre samotnú tlač aj charakter výrobkov… . Detailný popis vlastností a druhov živíc pre 3D tlač je uvedený v sekcii Živice.',
            links: [
              { text: 'Filamenty', page: PAGES.filaments },
              { text: 'Živice', page: PAGES.materialsAndProcedures },
            ],
          },
          {
            text: 'Dôležité pre kvalitnú tlač je výber vlákna resp. tekutej živice pri SLA tlačiarňach… , vlastnosti tlačiarne (rady pre výber tlačiarne) a parametre tlače, rozlíšenie…',
            links: [{ text: 'rady pre výber tlačiarne', page: PAGES.printerSelectionTips }],
          },
        ],
        subLinks: {
          title: 'Materíály',
          links: [
            { text: 'PLA', page: PAGES.materialPLA },
            { text: 'PET', page: PAGES.materialPET },
            { text: 'PETG', page: PAGES.materialPETG },
            { text: 'ABS', page: PAGES.materialABS },
            { text: 'TPE', page: PAGES.materialTPE },
            { text: 'Polyamid', page: PAGES.materialPolyamid },
          ],
        },
      },
    },
    [PAGES.printPrototyping]: {
      title: '3D Prototyping',
      body: [
        {
          text: 'Prevod Vašich ideí do výsledných kontúr objektov reálneho sveta je niekoľko stupňový proces. Začína samotnou predstavou o podobe predmetu a jeho vlastnostiach, toto je len prvá etapa tvorby. Druhou etapou je tvorba modelu tvarov a vlastností objektu definovaným modelom najčastejšie s využitím programov na základe CAD. Model v súboroch takýchto programov je následne prevedený na súbor zrozumiteľný pre 3D tlačiareň (napr. STL, 3MF atď.). Až po načítaní takéhoto súboru je 3D tlačiareň schopná previesť nápad k „životu“. Celý proces predstavuje tzv. prototyping.',
        },
        {
          text: 'Pre užívateľov či už ide o hobby alebo profesionálnych návrhárov je najťažší prechod od prvej etapy návrhu k modelu realizovanom vo vhodnom programe. Veľmi radi pomôžeme či už cennými radami alebo konkrétnymi vyhotoveniami modelov. Našim hlavným zameraním je však použitie tejto v mnohých aspektoch skvelej technológie v širších projektoch, kde sú možnosti individuálneho návrhu na mieru nedoceniteľné. V mnohých projektoch IoT, automatizácií, riadení, analýze kľúčových faktorov apod. sú možnosti špeciálneho návrhu na mieru natoľko ekonomické a výhodné, že je 3D prototyping obrovskou výhodou.',
        },
      ],
      en: {
        title: '3D Prototyping',
        body: [
          {
            text: 'Prevod Vašich ideí do výsledných kontúr objektov reálneho sveta je niekoľko stupňový proces. Začína samotnou predstavou o podobe predmetu a jeho vlastnostiach, toto je len prvá etapa tvorby. Druhou etapou je tvorba modelu tvarov a vlastností objektu definovaným modelom najčastejšie s využitím programov na základe CAD. Model v súboroch takýchto programov je následne prevedený na súbor zrozumiteľný pre 3D tlačiareň (napr. STL, 3MF atď.). Až po načítaní takéhoto súboru je 3D tlačiareň schopná previesť nápad k „životu“. Celý proces predstavuje tzv. prototyping.',
          },
          {
            text: 'Pre užívateľov či už ide o hobby alebo profesionálnych návrhárov je najťažší prechod od prvej etapy návrhu k modelu realizovanom vo vhodnom programe. Veľmi radi pomôžeme či už cennými radami alebo konkrétnymi vyhotoveniami modelov. Našim hlavným zameraním je však použitie tejto v mnohých aspektoch skvelej technológie v širších projektoch, kde sú možnosti individuálneho návrhu na mieru nedoceniteľné. V mnohých projektoch IoT, automatizácií, riadení, analýze kľúčových faktorov apod. sú možnosti špeciálneho návrhu na mieru natoľko ekonomické a výhodné, že je 3D prototyping obrovskou výhodou.',
          },
        ],
      },
    },
    [PAGES.printerSelectionTips]: {
      title: 'Rady pre výber tlačiarne',
      body: [
        {
          title: 'Tlačiarne FDM',
          text: 'Tieto tlačiarne využívajú vlákna na modelovanie objektov depozíciou z taveniny z angl. „fused deposition modeling (FDM).“ Vstupom sú  termoplastické materiály, ktoré roztavením, následným presným umiestnením a spevnením ochladením vytvoria požadovaný objekt. Táto kategória tlačiarní predstavuje typickú a užívateľsky najdostupnejšiu technológiu známych tlačových strún, ktoré sú východiskové materiály. Vznik dostupných jednotiek tohto typu sa datuje k roku 2009 a komunitnému projektu RepRap tiež z angl. „Replicating Rapid Prototyper“ (http://www.reprap.org). Porovnať kvalitu a spoľahlivosť rôznych výrobcov na trhu FDM tlačiarní je vhodné podľa nevyhnutných komponentov, ktoré sú súčasťou prakticky každého zariadenia, ktoré užívateľ zakupuje ako stavebnicu súčiastok alebo už zmontovaný  finálny výrobok. Charakter stavebnice vychádza z pôvodného projektu.',
          list: [
            {
              title: 'Vlákno',
              text: 'Nie je priamo dodané výrobcom, avšak už jeho voľbou sa výrazne ovplyvňuje kvalita tlače. Pri voľbe vlákna je nutné rešpektovať jeho priemer ako základný parameter, ktorý je jedinečný pre danú tlačiareň. Typické priemery vlákien na trhu sú: 1,75 mm a 2,85 mm (označovaný aj ako 3 mm). Nutné je rešpektovať kompatibilitu zariadenia k danému priemeru! Vlákno priemeru 1,75 mm je bežnejšie z dôvodu nižšej potrebnej pretlačnej sily. Škála rôznych materiálov, z ktorých sa vyrábajú tlačové struny pre 3D tlačiarne sa stále rozširuje, no je nutné dávať pozor na kompatibilitu materiálu s Vašim zariadením! Kompatibilita býva udávaná výrobcom zariadenia. Ceny strún na trhu sa veľmi líšia. Doporučuje sa nakupovať buď priamo od výrobcov tlačiarní alebo dodávateľov, ktorých produkty výrobca testoval, v týchto prípadoch sú ceny vyššie. Pokiaľ sa rozhodnete experimentovať je vhodné nakupovať od dodávateľov s množstvom užívateľských recenzií, prípadne dobrých skúseností od širšej komunity užívateľov 3D tlače, pri voľbe vlákna by nemala rozhodovať v prvom rade cena!',
            },
            {
              title: 'Extrudér',
              text: 'Komponenta, ktorá zabezpečí pohyb vlákna a jeho natavenie pri správnej teplote je extrudér. Skladá sa z kanála pre vedenie vlákna (ten je definovaný pre určitý priemer). V kanály je vlákno zahriate na požadovanú teplotu. Na počiatočné uchopenie vlákna slúži pohonný mechanizmus, ktorý je poháňaný hnacím motorom. Pohonný mechanizmus tlačí vlákno do už spomínaného vodivého kanála. Súčasťou pohonného mechanizmu je kladka, ktorá vlákno tlačí mechanizmom ďalej. Za kladkou nasleduje ohrevné teleso kanála izolované obalom a s teplotným senzorom, ktoré je zahriate až na bod topenia použitého vlákna. Roztavené vlákno ústi do trysky extrudéra, ktorou je dávkované na tlačovú podložku. Pod extrudérom sa nachádza aj ventilátor, ktorý je súčasťou chladiaceho systému a urýchľuje spevnenie dávkovaného materiálu. Konštrukcia extrudéru sa líši najmä tým, či ide o uzavretú jednoblokovú sústavu alebo naopak otvorenú modulárnejšiu sústavu, kde je možnosť vymieňať jednotlivé komponenty. Jednobloková sústava má výhodu robustnosti, presnosti a odolnosti voči chybám a znečisteniu, naopak otvorená sústava umožňuje výmenu opotrebovaných súčastí a prípadne možnosť vybavenie upgradovať. Mechanické pohyblivé časti a tryska extrudéru patria k najopotrebovávanejším častiam 3D tlačiarne.',
            },
            {
              title: 'Tlačová podložka',
              text: 'V podstate ide o jednoduchú plochu, ktorá je skonštruovaná s rôzneho materiálu pre dosiahnutie adhézie primárnej vrstvy tlačeného materiálu. Najčastejšie sa môžete stretnúť so sklenenými alebo akrylovými doskami. Pre dokonalú adhéziu je potrebné aby bola doska rovnobežná s rovinou pohybu extrudéra. Pokiaľ táto podmienka zaistená nie je strácajú výrobky súmernosť a symetriu. Neplatí, že doska by mala byť vo vodorovnej rovine, tlačovú dosku je potrebné vyrovnávať do roviny pohybu extrudéra! Jednoduchosť kalibrácie dosky by mala byť jedným so zvažovaných kritérií pre voľbu 3D tlačiarne. Na trhu je málo 3D tlačiarní, ktoré kalibráciu prevádzajú automaticky.',
            },
            {
              title: 'Lineárne krokové motory, ložiská, pásy a ďalšie súčasti lineárneho pohybu pri tlači',
              text: 'Pohyblivé časti sú kľúčovou časťou každej 3D tlačiarne, ktoré definujú kvalitu tlače. Kvalitu tlače môžme uvádzať rozlíšením tlače. V prípade 2D tlače na papier sa u atramentových tlačiarní uvádza rozlíšenie v atramentových bodoch na palec z angl. dots per inch (DPI), čím väčšie je rozlíšenie DPI (typicky 600 až 1200 DPI), tým väčšia je kvalita tlače. U 3D tlačiarní je to podobné, ale okrem bodového rozlíšenia v rovine XY sa uvažuje aj rozlíšenie v ose Z, ktoré je pri FDM tlačiarňach najčastejšou metrikou kvality tlače a udáva sa v mikrónoch (tisíciny mm). S rozlíšením tlačiarne súvisí ďalší dôležitý parameter, rýchlosť tlače. Platí, čí vyššia je hodnota rozlíšenia, tým nižšia bude rýchlosť tlače. Z posledných faktov je jasný význam pohyblivých súčastí na presnosť objektov a dobu potrebnú na ich výrobu. Na trhu sú dva rozdielne prístupy riadenia pohybu tlače, ktoré ale dosahujú podobné výsledky. Ide o typ karztézskeho posuvu, založená na pohyby v 3 osách: zľava-doprava v ose X, spredu-dozadu v ose Y a hore-dolu v ose Z. Druhým typom sú tlačiarne delta. Tieto pracujú spôsobom zdvih a umiestnenie materiálu (pick and place). Prakticky nepracujú s lineárny posuvom, ale s plávajúcim extrudérom, ktorý je posúvaný tromi ramenami pripojenými k lineárnym koľajniciam a remeniciam. Tento typ funguje tak, že koordinovaným pohybom ramien dochádza k riadenému všesmerovému pohybu extrudéra. Tento typ tlačiarní rieši problém kartézskych systémov s obmedzenou výškou modelovaních objektov.',
              highlights: ['rozlíšením tlače', 'rýchlosť tlače'],
            },
            {
              title: 'Rám tlačiarne',
              text: 'Rám alebo šasi dáva celému zariadeniu štruktúru a pevnosť. Zákazníci oceňujú najmä jeho estetickú funkciu, ktorá však nie je zďaleka jedinou, ktorú plní. Samozrejme atraktívny vzhľad prispieva k úspešnosti výrobku. Kvalitný rám je ukazovateľom prevedenia celého výrobku. Posudzujte však predovšetkým stabilitu uchopenia jednotlivých častí na ráme, robustnosť a odolnosť proti otrasom. Toto sú hlavné funkcie kvalitného rámu. Z hľadiska materiálu bývajú prevedenia rámu rôzne, dokonca niektorý výrobcovia dodávajú niektoré časti vytlačené 3D tlačou (má to svoje výhody v možnostiach upgradu, ale môže to spôsobovať nestabilitu), rámy bývajú drevené, ale za pevnostne najdostatočnejšie je treba považovať rámy kovové.',
            },
            {
              title: 'Ventilátory a ďalšie komponenty chladenia',
              text: 'Zabezpečuje chladenie k bodu tuhnutia vrstvy materiálu, býva umiestnený pod extrudérom, ale chladiace systémy sú často okrem toho umiestnené aj na iných miestach. Najčastejšie ide o PC ventilátory rôznej veľkosti väčšinou so vstupným napätím 12 V.',
            },
            {
              title: 'Elektronická jednotka radiča',
              text: 'Elektronická jednotka, ovládací mozog tlačiarne býva umiestnený rôzne, často pri zdroji elektrickej energie. Hardwarová a softwarová zložitosť jednotky je veľmi rôznorodá a závisí najmä od pokročilosti senzoriky tj. senzorov snímania pohybov, teploty, vlákna, senzormi zarovnania tlačovej dosky atď. Rozhodujúce je čo za vlastnosti od zariadenia samy očakávate.',
            },
          ],
        },
        {
          title: 'Tlačiarne SLA',
          text: 'V 3D tlačiarni je umiestnená vaňa s kvapalnou živicou.',
          list: [
            {
              title: 'Tlačová doska',
              text: 'sa pomalým pohybom ponára do vane a je orientovaná smerom dole. Medzi dnom vane a kovovou doskou zostáva len malý priestor s veľmi tenkou vrstvou živice.',
            },
            { title: 'Zdroj žiarenia ', text: 'vyžaruje na vytvrdenie živice vhodnú vlnovú dĺžku.' },
            {
              title: 'Optickým systémom',
              text: 'sú lúče žiarenia smerované na tlačovú dosku zo spodnej strany a dopadajú v miestach, kde má dôjsť k vytvrdeniu podľa nastaveného modelu. Kvalita a presnosť optického systému sú spolu s veľkosťou vlnovej dĺžky žiarenia zásadné pre dosiahnuteľné rozlíšenie 3D SLA tlačiarne.',
            },
            { title: 'Systém posuvu dosky', text: 'potom veľmi citlivo posunie dosku smerom nahor.' },
          ],
        },
        {
          text: 'Proces vytvárania štruktúry modelu pokračuje v ďalšej vrstve, ktorá sa pripojí k predchádzajúcej, cyklus sa opakuje až dovtedy, kým je objekt hotový.',
        },
      ],
      en: {
        title: 'Rady pre výber tlačiarne',
        body: [
          {
            title: 'Tlačiarne FDM',
            text: 'Tieto tlačiarne využívajú vlákna na modelovanie objektov depozíciou z taveniny z angl. „fused deposition modeling (FDM).“ Vstupom sú  termoplastické materiály, ktoré roztavením, následným presným umiestnením a spevnením ochladením vytvoria požadovaný objekt. Táto kategória tlačiarní predstavuje typickú a užívateľsky najdostupnejšiu technológiu známych tlačových strún, ktoré sú východiskové materiály. Vznik dostupných jednotiek tohto typu sa datuje k roku 2009 a komunitnému projektu RepRap tiež z angl. „Replicating Rapid Prototyper“ (http://www.reprap.org). Porovnať kvalitu a spoľahlivosť rôznych výrobcov na trhu FDM tlačiarní je vhodné podľa nevyhnutných komponentov, ktoré sú súčasťou prakticky každého zariadenia, ktoré užívateľ zakupuje ako stavebnicu súčiastok alebo už zmontovaný  finálny výrobok. Charakter stavebnice vychádza z pôvodného projektu.',
            list: [
              {
                title: 'Vlákno',
                text: 'Nie je priamo dodané výrobcom, avšak už jeho voľbou sa výrazne ovplyvňuje kvalita tlače. Pri voľbe vlákna je nutné rešpektovať jeho priemer ako základný parameter, ktorý je jedinečný pre danú tlačiareň. Typické priemery vlákien na trhu sú: 1,75 mm a 2,85 mm (označovaný aj ako 3 mm). Nutné je rešpektovať kompatibilitu zariadenia k danému priemeru! Vlákno priemeru 1,75 mm je bežnejšie z dôvodu nižšej potrebnej pretlačnej sily. Škála rôznych materiálov, z ktorých sa vyrábajú tlačové struny pre 3D tlačiarne sa stále rozširuje, no je nutné dávať pozor na kompatibilitu materiálu s Vašim zariadením! Kompatibilita býva udávaná výrobcom zariadenia. Ceny strún na trhu sa veľmi líšia. Doporučuje sa nakupovať buď priamo od výrobcov tlačiarní alebo dodávateľov, ktorých produkty výrobca testoval, v týchto prípadoch sú ceny vyššie. Pokiaľ sa rozhodnete experimentovať je vhodné nakupovať od dodávateľov s množstvom užívateľských recenzií, prípadne dobrých skúseností od širšej komunity užívateľov 3D tlače, pri voľbe vlákna by nemala rozhodovať v prvom rade cena!',
              },
              {
                title: 'Extrudér',
                text: 'Komponenta, ktorá zabezpečí pohyb vlákna a jeho natavenie pri správnej teplote je extrudér. Skladá sa z kanála pre vedenie vlákna (ten je definovaný pre určitý priemer). V kanály je vlákno zahriate na požadovanú teplotu. Na počiatočné uchopenie vlákna slúži pohonný mechanizmus, ktorý je poháňaný hnacím motorom. Pohonný mechanizmus tlačí vlákno do už spomínaného vodivého kanála. Súčasťou pohonného mechanizmu je kladka, ktorá vlákno tlačí mechanizmom ďalej. Za kladkou nasleduje ohrevné teleso kanála izolované obalom a s teplotným senzorom, ktoré je zahriate až na bod topenia použitého vlákna. Roztavené vlákno ústi do trysky extrudéra, ktorou je dávkované na tlačovú podložku. Pod extrudérom sa nachádza aj ventilátor, ktorý je súčasťou chladiaceho systému a urýchľuje spevnenie dávkovaného materiálu. Konštrukcia extrudéru sa líši najmä tým, či ide o uzavretú jednoblokovú sústavu alebo naopak otvorenú modulárnejšiu sústavu, kde je možnosť vymieňať jednotlivé komponenty. Jednobloková sústava má výhodu robustnosti, presnosti a odolnosti voči chybám a znečisteniu, naopak otvorená sústava umožňuje výmenu opotrebovaných súčastí a prípadne možnosť vybavenie upgradovať. Mechanické pohyblivé časti a tryska extrudéru patria k najopotrebovávanejším častiam 3D tlačiarne.',
              },
              {
                title: 'Tlačová podložka',
                text: 'V podstate ide o jednoduchú plochu, ktorá je skonštruovaná s rôzneho materiálu pre dosiahnutie adhézie primárnej vrstvy tlačeného materiálu. Najčastejšie sa môžete stretnúť so sklenenými alebo akrylovými doskami. Pre dokonalú adhéziu je potrebné aby bola doska rovnobežná s rovinou pohybu extrudéra. Pokiaľ táto podmienka zaistená nie je strácajú výrobky súmernosť a symetriu. Neplatí, že doska by mala byť vo vodorovnej rovine, tlačovú dosku je potrebné vyrovnávať do roviny pohybu extrudéra! Jednoduchosť kalibrácie dosky by mala byť jedným so zvažovaných kritérií pre voľbu 3D tlačiarne. Na trhu je málo 3D tlačiarní, ktoré kalibráciu prevádzajú automaticky.',
              },
              {
                title: 'Lineárne krokové motory, ložiská, pásy a ďalšie súčasti lineárneho pohybu pri tlači',
                text: 'Pohyblivé časti sú kľúčovou časťou každej 3D tlačiarne, ktoré definujú kvalitu tlače. Kvalitu tlače môžme uvádzať rozlíšením tlače. V prípade 2D tlače na papier sa u atramentových tlačiarní uvádza rozlíšenie v atramentových bodoch na palec z angl. dots per inch (DPI), čím väčšie je rozlíšenie DPI (typicky 600 až 1200 DPI), tým väčšia je kvalita tlače. U 3D tlačiarní je to podobné, ale okrem bodového rozlíšenia v rovine XY sa uvažuje aj rozlíšenie v ose Z, ktoré je pri FDM tlačiarňach najčastejšou metrikou kvality tlače a udáva sa v mikrónoch (tisíciny mm). S rozlíšením tlačiarne súvisí ďalší dôležitý parameter, rýchlosť tlače. Platí, čí vyššia je hodnota rozlíšenia, tým nižšia bude rýchlosť tlače. Z posledných faktov je jasný význam pohyblivých súčastí na presnosť objektov a dobu potrebnú na ich výrobu. Na trhu sú dva rozdielne prístupy riadenia pohybu tlače, ktoré ale dosahujú podobné výsledky. Ide o typ karztézskeho posuvu, založená na pohyby v 3 osách: zľava-doprava v ose X, spredu-dozadu v ose Y a hore-dolu v ose Z. Druhým typom sú tlačiarne delta. Tieto pracujú spôsobom zdvih a umiestnenie materiálu (pick and place). Prakticky nepracujú s lineárny posuvom, ale s plávajúcim extrudérom, ktorý je posúvaný tromi ramenami pripojenými k lineárnym koľajniciam a remeniciam. Tento typ funguje tak, že koordinovaným pohybom ramien dochádza k riadenému všesmerovému pohybu extrudéra. Tento typ tlačiarní rieši problém kartézskych systémov s obmedzenou výškou modelovaních objektov.',
                highlights: ['rozlíšením tlače', 'rýchlosť tlače'],
              },
              {
                title: 'Rám tlačiarne',
                text: 'Rám alebo šasi dáva celému zariadeniu štruktúru a pevnosť. Zákazníci oceňujú najmä jeho estetickú funkciu, ktorá však nie je zďaleka jedinou, ktorú plní. Samozrejme atraktívny vzhľad prispieva k úspešnosti výrobku. Kvalitný rám je ukazovateľom prevedenia celého výrobku. Posudzujte však predovšetkým stabilitu uchopenia jednotlivých častí na ráme, robustnosť a odolnosť proti otrasom. Toto sú hlavné funkcie kvalitného rámu. Z hľadiska materiálu bývajú prevedenia rámu rôzne, dokonca niektorý výrobcovia dodávajú niektoré časti vytlačené 3D tlačou (má to svoje výhody v možnostiach upgradu, ale môže to spôsobovať nestabilitu), rámy bývajú drevené, ale za pevnostne najdostatočnejšie je treba považovať rámy kovové.',
              },
              {
                title: 'Ventilátory a ďalšie komponenty chladenia',
                text: 'Zabezpečuje chladenie k bodu tuhnutia vrstvy materiálu, býva umiestnený pod extrudérom, ale chladiace systémy sú často okrem toho umiestnené aj na iných miestach. Najčastejšie ide o PC ventilátory rôznej veľkosti väčšinou so vstupným napätím 12 V.',
              },
              {
                title: 'Elektronická jednotka radiča',
                text: 'Elektronická jednotka, ovládací mozog tlačiarne býva umiestnený rôzne, často pri zdroji elektrickej energie. Hardwarová a softwarová zložitosť jednotky je veľmi rôznorodá a závisí najmä od pokročilosti senzoriky tj. senzorov snímania pohybov, teploty, vlákna, senzormi zarovnania tlačovej dosky atď. Rozhodujúce je čo za vlastnosti od zariadenia samy očakávate.',
              },
            ],
          },
          {
            title: 'Tlačiarne SLA',
            text: 'V 3D tlačiarni je umiestnená vaňa s kvapalnou živicou.',
            list: [
              {
                title: 'Tlačová doska',
                text: 'sa pomalým pohybom ponára do vane a je orientovaná smerom dole. Medzi dnom vane a kovovou doskou zostáva len malý priestor s veľmi tenkou vrstvou živice.',
              },
              { title: 'Zdroj žiarenia ', text: 'vyžaruje na vytvrdenie živice vhodnú vlnovú dĺžku.' },
              {
                title: 'Optickým systémom',
                text: 'sú lúče žiarenia smerované na tlačovú dosku zo spodnej strany a dopadajú v miestach, kde má dôjsť k vytvrdeniu podľa nastaveného modelu. Kvalita a presnosť optického systému sú spolu s veľkosťou vlnovej dĺžky žiarenia zásadné pre dosiahnuteľné rozlíšenie 3D SLA tlačiarne.',
              },
              { title: 'Systém posuvu dosky', text: 'potom veľmi citlivo posunie dosku smerom nahor.' },
            ],
          },
          {
            text: 'Proces vytvárania štruktúry modelu pokračuje v ďalšej vrstve, ktorá sa pripojí k predchádzajúcej, cyklus sa opakuje až dovtedy, kým je objekt hotový.',
          },
        ],
      },
    },

    [PAGES.iotSolutions]: {
      title: 'IoT Riešenia',
      body: [
        {
          text: 'IoT je skratka pre tzv. „internet vecí“ z angl. „Internet of Thing“ predstavuje fyzickú a logickú sieť s prepojením miliónov zariadení, senzorov a pracovných staníc s jedinečnou identifikáciou podľa unikátnej IP adresy. IoT umožňuje výmenu obrovského množstva dát z ľubovoľných z ľubovoľných prvkov systémov. Vhodne spracované dáta potom poskytujú informácie o procesoch a faktoroch, ktoré ich ovplyvňujú. Nevýhodami zavádzania IoT do všetkých procesov sú bezpečnostné riziká a tiež ľudské hľadisko, kedy môžu byť zamestnanci nižšej kvalifikácie považovaní za nadbytočných alebo môže dochádzať k celkovému znižovaniu ľudského kapitálu vo výrobných procesoch a s tým spojené rozsiahle problémy.',
        },
        {
          text: 'Našou filozofiou je zodpovedný návrh a zavádzanie inteligentných systémov s využitím moderných komunikačných prvkov IoT do procesov všade tam, kde sa významne pomôže identifikácií problémov, podporia sa inovácie, dochádza k lepšiemu využitiu zdrojov. Práve čo najlepšie využitie zdrojov zvyšuje ekológiu a to so zreteľom na zabezpečenie kľúčových informácií a človeka, pre ktorého majú tieto systémy slúžiť a zjednodušiť mu prácu nie naopak :). Sme schopný navrhovať sieťovú infraštruktúru pre malé a stredné podniky a zaistiť tým tepny a žily pre navzájom komunikujúce systémy. Okrem IoT sú v tomto prístupe viditeľné prvky konceptu moderného priemyslu „Industry 4.0“!',
          highlights: ['návrh', 'zavádzanie inteligentných systémov s využitím moderných komunikačných prvkov IoT do procesov', '„Industry 4.0“'],
        },
      ],
      links: [
        { icon: 'intelligentHouse', title: 'Inteligentné systémy', page: PAGES.intelligentSystems },
        { icon: 'storage', title: 'Siete', page: PAGES.networks },
        { icon: 'intelligent', title: 'Komunikačné prvky', page: PAGES.communicationElements },
      ],
      en: {
        title: 'IoT Riešenia',
        body: [
          {
            text: 'IoT je skratka pre tzv. „internet vecí“ z angl. „Internet of Thing“ predstavuje fyzickú a logickú sieť s prepojením miliónov zariadení, senzorov a pracovných staníc s jedinečnou identifikáciou podľa unikátnej IP adresy. IoT umožňuje výmenu obrovského množstva dát z ľubovoľných z ľubovoľných prvkov systémov. Vhodne spracované dáta potom poskytujú informácie o procesoch a faktoroch, ktoré ich ovplyvňujú. Nevýhodami zavádzania IoT do všetkých procesov sú bezpečnostné riziká a tiež ľudské hľadisko, kedy môžu byť zamestnanci nižšej kvalifikácie považovaní za nadbytočných alebo môže dochádzať k celkovému znižovaniu ľudského kapitálu vo výrobných procesoch a s tým spojené rozsiahle problémy.',
          },
          {
            text: 'Našou filozofiou je zodpovedný návrh a zavádzanie inteligentných systémov s využitím moderných komunikačných prvkov IoT do procesov všade tam, kde sa významne pomôže identifikácií problémov, podporia sa inovácie, dochádza k lepšiemu využitiu zdrojov. Práve čo najlepšie využitie zdrojov zvyšuje ekológiu a to so zreteľom na zabezpečenie kľúčových informácií a človeka, pre ktorého majú tieto systémy slúžiť a zjednodušiť mu prácu nie naopak :). Sme schopný navrhovať sieťovú infraštruktúru pre malé a stredné podniky a zaistiť tým tepny a žily pre navzájom komunikujúce systémy. Okrem IoT sú v tomto prístupe viditeľné prvky konceptu moderného priemyslu „Industry 4.0“!',
            highlights: ['návrh', 'zavádzanie inteligentných systémov s využitím moderných komunikačných prvkov IoT do procesov', '„Industry 4.0“'],
          },
        ],
        links: [
          { icon: 'intelligentHouse', title: 'Inteligentné systémy', page: PAGES.intelligentSystems },
          { icon: 'storage', title: 'Siete', page: PAGES.networks },
          { icon: 'intelligent', title: 'Komunikačné prvky', page: PAGES.communicationElements },
        ],
      },
    },
    [PAGES.intelligentSystems]: {
      title: 'Inteligentné systémy',
      description: '"Internet of Things"',
      body: [
        {
          text: 'Inteligentné systémy navzájom komunikujúce a prepojené v tzv. „Internete vecí“ (IoT, Internet of Things) predstavujú revolúciu v komunikácií, priemysle, biznise a spoločnosti vôbec. Možnosť zapojiť obrovské množstvo senzorov, smart zariadení a vstavaných (embedded) systémov tvoriacich funkčné časti väčších celkov. Zapojením inteligentných systémov a využitie moderných komunikačných technológií prináša veľmi veľké množstvo výhod pri:',
          highlights: [''],
          list: [
            { text: 'riadení procesov' },
            { text: 'rozhodovaní' },
            { text: 'zisťovaní kritických miest' },
            { text: 'získavaní kľúčových dát s ich štatistickom spracovaní' },
          ],
        },
      ],
      en: {
        title: 'Inteligentné systémy',
        description: '"Internet of Things"',
        body: [
          {
            text: 'Inteligentné systémy navzájom komunikujúce a prepojené v tzv. „Internete vecí“ (IoT, Internet of Things) predstavujú revolúciu v komunikácií, priemysle, biznise a spoločnosti vôbec. Možnosť zapojiť obrovské množstvo senzorov, smart zariadení a vstavaných (embedded) systémov tvoriacich funkčné časti väčších celkov. Zapojením inteligentných systémov a využitie moderných komunikačných technológií prináša veľmi veľké množstvo výhod pri:',
            highlights: [''],
            list: [
              { text: 'riadení procesov' },
              { text: 'rozhodovaní' },
              { text: 'zisťovaní kritických miest' },
              { text: 'získavaní kľúčových dát s ich štatistickom spracovaní' },
            ],
          },
        ],
      },
    },
    [PAGES.networks]: {
      title: 'Siete',
      body: [
        {
          text: 'Siete sú tvorené dôležitými cestami pre sprostredkovanie komunikácie medzi ľuďmi, zariadeniami alebo medzi ľuďmi a zariadeniami navzájom. Ide o trochu abstraktný pojem, ktorý zahŕňa veľký súhrn zariadení a pravidiel riadiacich výmenu správ a informácií. Nepochybne sú siete tepnami a žilami firiem aj domácností. V súčasnosti s nástupom IoT do sietí pribúdajú milióny zariadení umožňujúce väčšiu kontrolu nad procesmi, ich sledovanie a štatistické vyhodnocovanie veľmi cenných až konkurenčných dát. Samozrejme aj obyčajný prístup do internetu je doménou návrhu a spravovania sietí. Siete sú ďaleko zložitejšie štruktúry než aby sa dali vyjadriť jednoduchou a bežnou predstavou o zdrojovom zariadení informácií a zariadení príjemcu týchto informácií. SIete sa podľa účelu líšia tvarom, veľkosťou a funkciou.',
        },
        {
          text: 'My sme schopní poskytnúť návrh, realizáciu a nastavenie efektívnych zapojení zariadení do sietí pre potreby výrobných podnikov, firiem, kancelárií aj domácností, so zameraním nielen pre prístup na internet, ale aj pre zložitejšie infraštruktúry potrebné pre správu smart zariadení, senzorov pre zber dôležitých dát a následné štatistické spracovanie, kde sa z dát môžu stať cenné informácie.',
        },
      ],
      en: {
        title: 'Siete',
        body: [
          {
            text: 'Siete sú tvorené dôležitými cestami pre sprostredkovanie komunikácie medzi ľuďmi, zariadeniami alebo medzi ľuďmi a zariadeniami navzájom. Ide o trochu abstraktný pojem, ktorý zahŕňa veľký súhrn zariadení a pravidiel riadiacich výmenu správ a informácií. Nepochybne sú siete tepnami a žilami firiem aj domácností. V súčasnosti s nástupom IoT do sietí pribúdajú milióny zariadení umožňujúce väčšiu kontrolu nad procesmi, ich sledovanie a štatistické vyhodnocovanie veľmi cenných až konkurenčných dát. Samozrejme aj obyčajný prístup do internetu je doménou návrhu a spravovania sietí. Siete sú ďaleko zložitejšie štruktúry než aby sa dali vyjadriť jednoduchou a bežnou predstavou o zdrojovom zariadení informácií a zariadení príjemcu týchto informácií. SIete sa podľa účelu líšia tvarom, veľkosťou a funkciou.',
          },
          {
            text: 'My sme schopní poskytnúť návrh, realizáciu a nastavenie efektívnych zapojení zariadení do sietí pre potreby výrobných podnikov, firiem, kancelárií aj domácností, so zameraním nielen pre prístup na internet, ale aj pre zložitejšie infraštruktúry potrebné pre správu smart zariadení, senzorov pre zber dôležitých dát a následné štatistické spracovanie, kde sa z dát môžu stať cenné informácie.',
          },
        ],
      },
    },
    [PAGES.communicationElements]: {
      title: 'Komunikačné prvky',
      body: [
        {
          text: 'Jednotlivé zariadenia sietí ako sú routre, switche, modemy, huby, úložiská dát, ale aj zariadenia odosielajúce dáta ako senzory, aktuátory ai. sú komunikačnými prvkami sietí. Tieto zariadenia bývajú veľmi sofistikované a napriek vysokej kvalite a spoľahlivosti týchto zariadení môže byť často potrebná ich výmena z dôvodu nedostatočnej funkcie či z dôvodov preprojektovania siete.',
        },
        {
          text: 'V prípade potreby zásahov do infraštruktúry siete poskytujeme:',
          list: [
            { text: 'Predaj routrov, switchov, modemov, hubov a ďalších sieťových prvkov' },
            { text: 'Návrh a doporučenia pre budovanie senzorických a inteligentných sietí s potrebnými prvkami' },
            { text: 'Nastavenia nami dodaných zariadení na základe požiadavkou Vašej používanej siete, prípadne budovanej siete' },
          ],
        },
      ],
      en: {
        title: 'Komunikačné prvky',
        body: [
          {
            text: 'Jednotlivé zariadenia sietí ako sú routre, switche, modemy, huby, úložiská dát, ale aj zariadenia odosielajúce dáta ako senzory, aktuátory ai. sú komunikačnými prvkami sietí. Tieto zariadenia bývajú veľmi sofistikované a napriek vysokej kvalite a spoľahlivosti týchto zariadení môže byť často potrebná ich výmena z dôvodu nedostatočnej funkcie či z dôvodov preprojektovania siete.',
          },
          {
            text: 'V prípade potreby zásahov do infraštruktúry siete poskytujeme:',
            list: [
              { text: 'Predaj routrov, switchov, modemov, hubov a ďalších sieťových prvkov' },
              { text: 'Návrh a doporučenia pre budovanie senzorických a inteligentných sietí s potrebnými prvkami' },
              { text: 'Nastavenia nami dodaných zariadení na základe požiadavkou Vašej používanej siete, prípadne budovanej siete' },
            ],
          },
        ],
      },
    },

    [PAGES.technicalChemistry]: {
      title: 'Technická chémia',
      body: [
        {
          text: 'Pod pojmom technická chémia sa rozumie oblasť tak široká, že jej úplné obsiahnutie v jedinej spoločnosti s danou ľudskou kapacitou nie je možné jednoducho preto, že by sme museli zamestnať milióny odborníkov z rôznych odvetví a krajín to samozrejme nejde :-). Účelné je zamerať sa na tie najbežnejšie potreby, ktoré je možné prostredníctvom technickej chémie riešiť a naopak špecializovať sa na potreby zákazníkov pre potreby ich odvetvia v prostredí podnikania. Pod technickú chémiu možno zahrnúť chémiu materiálov – odvetvia vyrábajúce, spracovávajúce a upravujúce rôzne materiály ako kovy, keramiku, sklá, plasty až po moderné kompozitné materiály. S materiálmi veľmi súvisí problematika ich spájania tj. spôsoby tvorby adhéznych a kohéznych spojov (lepenia). Často býva dôležité ich čistenie, kde ide o efektivitu a rýchlosť týchto procesov pre následné úpravy materiálov alebo finálnych výrobkov, kde sa v súčasnosti veľmi vyzdvihuje ekologické hľadisko. Koniec životného cyklu výrobku je charakteristický snahou o ekologickú likvidáciu a dosiahnutie, čo najvyššieho stupňa recyklácie. Osobitne zaraďujeme do technickej chémie oblasti, ktoré sú využívané v rámci našej činnosti a poradenstva. Ide o chémiu moderných a rastúcich oblastí 3D tlače a chémiu pre elektroniku, kde sa neustále objavujú nové trendy.',
          highlights: ['lepenia', 'čistenie', 'úpravy materiálov', 'ekologickú likvidáciu', 'recyklácie', '3D tlače', 'elektroniku'],
        },
        {
          text: 'Možnosti technickej chémie sú neobmedzené a neustále sa zdokonaľujú postupy, ktoré možno priamo aplikovať vo výrobe, spracovaní, ochranných opatreniach pre rôzne materiály a výrobky z nich. My využívame naše skúsenosti pre návrh rôznych postupov s využitím ekonomicky a ekologicky vhodnej chémie, venujeme sa oblastiam:',
          list: [
            { text: 'antikoróznych opatrení kovov a plastov' },
            { text: 'povrchovým úpravám kovov, plastov a kompozitov pre ochranné, funkčné a estetické účely' },
            { text: 'čisteniu povrchov všetkých druhov materiálov a hľadanie riešení prípadných problémov spôsobených chemickým znečistením' },
            { text: 'lepeniu povrchov rôznych druhov materiálov' },
            { text: 'rozpúšťaniu, rozkladu a recyklácií materiálových odpadov' },
            {
              text: 'výskumu zložitejších problémov, ktoré sú súčasťou výrobných, spracovateľských alebo funkčných častí životného cyklu výrobkov s nutnosťou hlbších analýz a experimentov',
            },
            { text: 'elektrochemickým metódam v rámci úprav materiálov alebo elektroniky' },
            { text: 'špeciálnym prípravkom na starostlivosť o funkčné oblečenie' },
            { text: 'ošetrovaniu optických komponentov a ich údržba' },
          ],
        },
        {
          text: 'Elektronika a mikroelektronika sú extrémne rýchlo rastúce odbory. Vo výrobách a servise elektroniky sa rieši úprava elektrických, materiálových, tepelných, optických atď. vlastností používaním širokej škály chemických prípravkov. V súčasnosti sa dôraz kladie najmä na kvalitu, cenu a ekológiu takýchto prostriedkov. Požiadavky si veľmi často odporujú preto je nutné dobre zvážiť, ktorá s možností je najvýhodnejšia pre danú aplikáciu.',
        },
      ],
      links: [
        { icon: 'hardware', title: 'Chémia pre materiály', page: PAGES.chemistryOfMaterials },
        { icon: 'chemistry', title: 'Chémia pre elektroniku', page: PAGES.chemistryForElectronics },
      ],
      en: {
        title: 'Technická chémia',
        body: [
          {
            text: 'Pod pojmom technická chémia sa rozumie oblasť tak široká, že jej úplné obsiahnutie v jedinej spoločnosti s danou ľudskou kapacitou nie je možné jednoducho preto, že by sme museli zamestnať milióny odborníkov z rôznych odvetví a krajín to samozrejme nejde :-). Účelné je zamerať sa na tie najbežnejšie potreby, ktoré je možné prostredníctvom technickej chémie riešiť a naopak špecializovať sa na potreby zákazníkov pre potreby ich odvetvia v prostredí podnikania. Pod technickú chémiu možno zahrnúť chémiu materiálov – odvetvia vyrábajúce, spracovávajúce a upravujúce rôzne materiály ako kovy, keramiku, sklá, plasty až po moderné kompozitné materiály. S materiálmi veľmi súvisí problematika ich spájania tj. spôsoby tvorby adhéznych a kohéznych spojov (lepenia). Často býva dôležité ich čistenie, kde ide o efektivitu a rýchlosť týchto procesov pre následné úpravy materiálov alebo finálnych výrobkov, kde sa v súčasnosti veľmi vyzdvihuje ekologické hľadisko. Koniec životného cyklu výrobku je charakteristický snahou o ekologickú likvidáciu a dosiahnutie, čo najvyššieho stupňa recyklácie. Osobitne zaraďujeme do technickej chémie oblasti, ktoré sú využívané v rámci našej činnosti a poradenstva. Ide o chémiu moderných a rastúcich oblastí 3D tlače a chémiu pre elektroniku, kde sa neustále objavujú nové trendy.',
            highlights: ['lepenia', 'čistenie', 'úpravy materiálov', 'ekologickú likvidáciu', 'recyklácie', '3D tlače', 'elektroniku'],
          },
          {
            text: 'Možnosti technickej chémie sú neobmedzené a neustále sa zdokonaľujú postupy, ktoré možno priamo aplikovať vo výrobe, spracovaní, ochranných opatreniach pre rôzne materiály a výrobky z nich. My využívame naše skúsenosti pre návrh rôznych postupov s využitím ekonomicky a ekologicky vhodnej chémie, venujeme sa oblastiam:',
            list: [
              { text: 'antikoróznych opatrení kovov a plastov' },
              { text: 'povrchovým úpravám kovov, plastov a kompozitov pre ochranné, funkčné a estetické účely' },
              { text: 'čisteniu povrchov všetkých druhov materiálov a hľadanie riešení prípadných problémov spôsobených chemickým znečistením' },
              { text: 'lepeniu povrchov rôznych druhov materiálov' },
              { text: 'rozpúšťaniu, rozkladu a recyklácií materiálových odpadov' },
              {
                text: 'výskumu zložitejších problémov, ktoré sú súčasťou výrobných, spracovateľských alebo funkčných častí životného cyklu výrobkov s nutnosťou hlbších analýz a experimentov',
              },
              { text: 'elektrochemickým metódam v rámci úprav materiálov alebo elektroniky' },
              { text: 'špeciálnym prípravkom na starostlivosť o funkčné oblečenie' },
              { text: 'ošetrovaniu optických komponentov a ich údržba' },
            ],
          },
          {
            text: 'Elektronika a mikroelektronika sú extrémne rýchlo rastúce odbory. Vo výrobách a servise elektroniky sa rieši úprava elektrických, materiálových, tepelných, optických atď. vlastností používaním širokej škály chemických prípravkov. V súčasnosti sa dôraz kladie najmä na kvalitu, cenu a ekológiu takýchto prostriedkov. Požiadavky si veľmi často odporujú preto je nutné dobre zvážiť, ktorá s možností je najvýhodnejšia pre danú aplikáciu.',
          },
        ],
        links: [
          { icon: 'hardware', title: 'Chémia pre materiály', page: PAGES.chemistryOfMaterials },
          { icon: 'chemistry', title: 'Chémia pre elektroniku', page: PAGES.chemistryForElectronics },
        ],
      },
    },
    [PAGES.chemistryForElectronics]: {
      title: 'Chémia pre elektroniku',
      description:
        'Elektronika a mikroelektronika sú extrémne rýchlo rastúce odbory. Vo výrobách a servise elektroniky sa rieši úprava elektrických, materiálových, tepelných, optických atď. vlastností používaním širokej škály chemických prípravkov. V súčasnosti sa dôraz kladie najmä na kvalitu, cenu a ekológiu takýchto prostriedkov. Požiadavky si veľmi často odporujú preto je nutné dobre zvážiť, ktorá s možností je najvýhodnejšia pre danú aplikáciu.',
      body: [
        {
          title: 'Tavivá',
          text: 'Kvapaliny, plyny alebo pevné látky, ktoré podporujú zmáčanie spájkovaných materiálov spájkou, tiež odstraňujú nečistoty najmä oxidického charakteru vznikajúce pri pájkovaní, zlepšujú prenos tepla a zabraňujú reoxidácií povrchov. Chemicky ide o prírodné živice (kalafuna) alebo syntetické, rozpustné alebo nerozpustné organické látky a anorganické kyseliny, zásady alebo soli. Z voľbou taviva pre daný povrch alebo aplikáciu Vám tiež radi pomôžeme.',
        },
        {
          title: 'Spájka',
          text: 'Spájky dostanete ako zliatinové vo forme kovových trubičiek, valčekov, drôtikov apod. alebo vo forme pasty ako spájkovacie pasty. Spájky vytvárajú elektrické prepojenia a tiež mechanicky fixujú súčiastky k doskám plošných spojov. Pri spájkach sa nutne sleduje veľa parametrov najmň teplota topenia a tuhnutia, povrchové napätie, teplotná rozťažnosť a elektrická alebo tepelná vodivosť. Z hľadiska zloženia je aktuálne rozdelenie na klasické olovnaté spájky a bezolovnaté spájky, ktoré sú trendom. Radi Vám poradíme s voľbou spájky aj vhodným postupom k spájkovaniu.',
        },
        {
          title: 'Lepidlá pre povrchovú montáž (SMT)',
          text: 'Používajú sa pre vytvorenie mechanickej a chemickej väzby súčiastky s povrchom plošnej dosky. Vyrábajú sa dve základné typy a to lepidlá elektroizolačné a elektricky vodivé. Požaduje sa dobrá lepivosť k povrchu, správna viskozita, chemická a teplotná stabilita, výrazná farebnosť pre optické kontroly, dlhodobá skladovateľnosť a mnoho iných faktorov. Významné sú tiež ekologické požiadavky na nízku toxicitu, nehorľavosť a zápach. Chemicky ide o živice, ktoré sú vytvrdzované teplom alebo žiarením. Problematika lepenia je obzvlášť v elektroniky, kde sú vysoké požiadavky na spoje náročná, preto Vám radi pomôžeme.',
        },
      ],
      en: {
        title: 'Chémia pre elektroniku',
        description:
          'Elektronika a mikroelektronika sú extrémne rýchlo rastúce odbory. Vo výrobách a servise elektroniky sa rieši úprava elektrických, materiálových, tepelných, optických atď. vlastností používaním širokej škály chemických prípravkov. V súčasnosti sa dôraz kladie najmä na kvalitu, cenu a ekológiu takýchto prostriedkov. Požiadavky si veľmi často odporujú preto je nutné dobre zvážiť, ktorá s možností je najvýhodnejšia pre danú aplikáciu.',
        body: [
          {
            title: 'Tavivá',
            text: 'Kvapaliny, plyny alebo pevné látky, ktoré podporujú zmáčanie spájkovaných materiálov spájkou, tiež odstraňujú nečistoty najmä oxidického charakteru vznikajúce pri pájkovaní, zlepšujú prenos tepla a zabraňujú reoxidácií povrchov. Chemicky ide o prírodné živice (kalafuna) alebo syntetické, rozpustné alebo nerozpustné organické látky a anorganické kyseliny, zásady alebo soli. Z voľbou taviva pre daný povrch alebo aplikáciu Vám tiež radi pomôžeme.',
          },
          {
            title: 'Spájka',
            text: 'Spájky dostanete ako zliatinové vo forme kovových trubičiek, valčekov, drôtikov apod. alebo vo forme pasty ako spájkovacie pasty. Spájky vytvárajú elektrické prepojenia a tiež mechanicky fixujú súčiastky k doskám plošných spojov. Pri spájkach sa nutne sleduje veľa parametrov najmň teplota topenia a tuhnutia, povrchové napätie, teplotná rozťažnosť a elektrická alebo tepelná vodivosť. Z hľadiska zloženia je aktuálne rozdelenie na klasické olovnaté spájky a bezolovnaté spájky, ktoré sú trendom. Radi Vám poradíme s voľbou spájky aj vhodným postupom k spájkovaniu.',
          },
          {
            title: 'Lepidlá pre povrchovú montáž (SMT)',
            text: 'Používajú sa pre vytvorenie mechanickej a chemickej väzby súčiastky s povrchom plošnej dosky. Vyrábajú sa dve základné typy a to lepidlá elektroizolačné a elektricky vodivé. Požaduje sa dobrá lepivosť k povrchu, správna viskozita, chemická a teplotná stabilita, výrazná farebnosť pre optické kontroly, dlhodobá skladovateľnosť a mnoho iných faktorov. Významné sú tiež ekologické požiadavky na nízku toxicitu, nehorľavosť a zápach. Chemicky ide o živice, ktoré sú vytvrdzované teplom alebo žiarením. Problematika lepenia je obzvlášť v elektroniky, kde sú vysoké požiadavky na spoje náročná, preto Vám radi pomôžeme.',
          },
        ],
      },
    },
    [PAGES.chemistryOfMaterials]: {
      title: 'Chémia pre materiály',
      body: [
        {
          title: 'Antikorózne prostriedky a povrchová úprava',
          text: 'Znehodnocovanie materiálov koróziou je problém, ktoré spôsobuje obrovské škody v priemysle aj domácnostiach, zároveň často predstavuje bezpečnostné riziko. Koróziou sú najviac dotknuté kovové materiály, ale týka sa aj plastov, kompozitov atď. Korózií sa dá veľmi často úspešne predchádzať prevenciou, širokou škálou chemických prostriedkov alebo vhodnou povrchovou úpravou. Niekedy je dokonca možné koróziu účinne odstrániť. S voľbou optimálnej ochrany a vhodných postupov Vám radi pomôžeme! Prípadne vieme problémy konzultovať a poradiť 🙂',
        },
        {
          title: 'Lepenie',
          text: 'Spájanie materiálov, ktoré majú rôznu chemickú povahu, býva často náročný technický problém. Lepenie sa stáva preferovaným spôsobom spájania. Ide o veľmi výhodný postup, ale stáva sa, že je nezriedka nutné riešiť práve zlyhanie adhézie takýchto spojov. Na trhu je dostupné veľké množstvo prostriedkov. Budeme radi, keď Vám, budeme môcť pri riešení ťažkostí s lepenými spojmi pomôcť!',
        },
        {
          title: 'Recyklácia',
          text: 'Problematika odpadov a ekológie je dnes už veľmi naliehavá. Nejde len o vhodný postup likvidácie odpadových materiálov a chémie. Úsporu nákladov a ekologické správanie je možné dosiahnuť hľadaním možností využitia nepotrebných materiálov, komponentov, výrobkov… Pre nás je zadosťučinením a presvedčením hľadať spôsoby, ktoré chránia prírodné zdroje, umožňujú čo najekologickejšiu likvidáciu alebo dávajú nové možnosti využitia materiálov. Špecializujeme sa na používanie ekologických rozpúšťadiel pri likvidácií chemických a materiálových odpadov.',
        },
        {
          title: 'Starostlivosť o funkčné oblečenie',
          text: 'Vášnivý športovci a turisti, ktorí investujú nemalé prostriedky do funkčného oblečenia a obuvy, využívajú jeho vlastnosti (elasticitu, priedušnosť, nepremokavosť, tepelnú izoláciu apod.) často extrémnym spôsobom. Existujú moderné dizajny oblečenia, ktoré sú naozaj výsledkom vedeckého skúmania. Bohužiaľ aj takto pokročilé oblečenie vyžaduje špeciálnu starostlivosť, ktorá súvisí s materiálovým prevedením a nastavením jeho vlastností. Ako ďalší milovníci športových kúskov 🙂 sa s Vami podelíme o dlhoročné skúsenosti a ponúkneme Vám tie najlepšie produkty a spôsoby ošetrenia pre isto nie práve lacné kusy v šatníku.',
        },
        {
          title: 'Starostlivosť o optiku',
          text: 'Optické prístroje ako sú fotoaparáty, ďalekohlady, mikroskopy a teleskopy sú závislé na čistote a správnej starostlivosti o optické časti. Najtypyckejším problémom je prašnosť a mastnota spôsobená nesprávnou manipuláciou najmä rukami. Ponúkame produkty na udržiavania čistoty, chemickú ochranu a zaistenie kvalitných vlastností optických zariadení. Naše služby ocenia fotografi, laboratória využívajúce optické analytické prístroje, ale aj amatérsky astronómovia.',
        },
      ],
      en: {
        title: 'Chémia pre materiály',
        body: [
          {
            title: 'Antikorózne prostriedky a povrchová úprava',
            text: 'Znehodnocovanie materiálov koróziou je problém, ktoré spôsobuje obrovské škody v priemysle aj domácnostiach, zároveň často predstavuje bezpečnostné riziko. Koróziou sú najviac dotknuté kovové materiály, ale týka sa aj plastov, kompozitov atď. Korózií sa dá veľmi často úspešne predchádzať prevenciou, širokou škálou chemických prostriedkov alebo vhodnou povrchovou úpravou. Niekedy je dokonca možné koróziu účinne odstrániť. S voľbou optimálnej ochrany a vhodných postupov Vám radi pomôžeme! Prípadne vieme problémy konzultovať a poradiť 🙂',
          },
          {
            title: 'Lepenie',
            text: 'Spájanie materiálov, ktoré majú rôznu chemickú povahu, býva často náročný technický problém. Lepenie sa stáva preferovaným spôsobom spájania. Ide o veľmi výhodný postup, ale stáva sa, že je nezriedka nutné riešiť práve zlyhanie adhézie takýchto spojov. Na trhu je dostupné veľké množstvo prostriedkov. Budeme radi, keď Vám, budeme môcť pri riešení ťažkostí s lepenými spojmi pomôcť!',
          },
          {
            title: 'Recyklácia',
            text: 'Problematika odpadov a ekológie je dnes už veľmi naliehavá. Nejde len o vhodný postup likvidácie odpadových materiálov a chémie. Úsporu nákladov a ekologické správanie je možné dosiahnuť hľadaním možností využitia nepotrebných materiálov, komponentov, výrobkov… Pre nás je zadosťučinením a presvedčením hľadať spôsoby, ktoré chránia prírodné zdroje, umožňujú čo najekologickejšiu likvidáciu alebo dávajú nové možnosti využitia materiálov. Špecializujeme sa na používanie ekologických rozpúšťadiel pri likvidácií chemických a materiálových odpadov.',
          },
          {
            title: 'Starostlivosť o funkčné oblečenie',
            text: 'Vášnivý športovci a turisti, ktorí investujú nemalé prostriedky do funkčného oblečenia a obuvy, využívajú jeho vlastnosti (elasticitu, priedušnosť, nepremokavosť, tepelnú izoláciu apod.) často extrémnym spôsobom. Existujú moderné dizajny oblečenia, ktoré sú naozaj výsledkom vedeckého skúmania. Bohužiaľ aj takto pokročilé oblečenie vyžaduje špeciálnu starostlivosť, ktorá súvisí s materiálovým prevedením a nastavením jeho vlastností. Ako ďalší milovníci športových kúskov 🙂 sa s Vami podelíme o dlhoročné skúsenosti a ponúkneme Vám tie najlepšie produkty a spôsoby ošetrenia pre isto nie práve lacné kusy v šatníku.',
          },
          {
            title: 'Starostlivosť o optiku',
            text: 'Optické prístroje ako sú fotoaparáty, ďalekohlady, mikroskopy a teleskopy sú závislé na čistote a správnej starostlivosti o optické časti. Najtypyckejším problémom je prašnosť a mastnota spôsobená nesprávnou manipuláciou najmä rukami. Ponúkame produkty na udržiavania čistoty, chemickú ochranu a zaistenie kvalitných vlastností optických zariadení. Naše služby ocenia fotografi, laboratória využívajúce optické analytické prístroje, ale aj amatérsky astronómovia.',
          },
        ],
      },
    },

    [PAGES.materialPLA]: {
      title: 'PLA',
      description: 'Polylactic acid',
      body: [
        {
          text: 'PLA (polylactic acid), čiže kyselina polymliečna je biologicky rozložiteľný polysacharid. Používa sa najmä z ekologických dôvodov, dostupnosti a kvalitných pevnostných vlastností. Pre 3D tlač ide o výborný a základný materiál. Farba a ďalšie vlastnosti sú určované rôznymi plnivami, napr. kovmi sa PLA plní až do obsahu 70 %. Plnením sa pripravujú aj „svetielkujúce“ filamenty PLA. O PLA môžme povedať, že ide o najčastejšiu voľbu pre FDM tlačiarne.',
        },
        {
          text: 'Najväčšou výhodou tohto materiálu je veľmi dobrá pevnosť, pomerne dobrá dostupnosť vzhľadom k tomu, že sa v najväčšej miere vyrába z biologických zdrojov ako napr. kukurica s využitím fermentačných procesov. Môžme povedať, že jeho výroba aj použitie je ekologické (v prírode rozložiteľný :-)).',
        },
        {
          text: 'Hlavnou nevýhodou je strata vlastností s rastúcou teplotou, už pri teplote 70 °C sa stáva plastickým. Takáto teplota je bežná aj v aute stojacom na slnku, preto je potrebné použiť ho pre výrobky, ktoré nebudú takýmto extrémom vystavené.}',
        },
      ],
      en: {
        title: 'PLA',
        description: 'Polylactic acid',
        body: [
          {
            text: 'PLA (polylactic acid), čiže kyselina polymliečna je biologicky rozložiteľný polysacharid. Používa sa najmä z ekologických dôvodov, dostupnosti a kvalitných pevnostných vlastností. Pre 3D tlač ide o výborný a základný materiál. Farba a ďalšie vlastnosti sú určované rôznymi plnivami, napr. kovmi sa PLA plní až do obsahu 70 %. Plnením sa pripravujú aj „svetielkujúce“ filamenty PLA. O PLA môžme povedať, že ide o najčastejšiu voľbu pre FDM tlačiarne.',
          },
          {
            text: 'Najväčšou výhodou tohto materiálu je veľmi dobrá pevnosť, pomerne dobrá dostupnosť vzhľadom k tomu, že sa v najväčšej miere vyrába z biologických zdrojov ako napr. kukurica s využitím fermentačných procesov. Môžme povedať, že jeho výroba aj použitie je ekologické (v prírode rozložiteľný :-)).',
          },
          {
            text: 'Hlavnou nevýhodou je strata vlastností s rastúcou teplotou, už pri teplote 70 °C sa stáva plastickým. Takáto teplota je bežná aj v aute stojacom na slnku, preto je potrebné použiť ho pre výrobky, ktoré nebudú takýmto extrémom vystavené.}',
          },
        ],
      },
    },
    [PAGES.materialPET]: {
      title: 'PET',
      description: 'Polyethylene terephthalate',
      body: [
        {
          text: 'PET (polyethylene terephthalate) alebo polyetyléntereftalát je polyester, ktorý určite poznáte z plastových fľašiek (podľa materiálu sa nazývajú PETky). Výrobou je možné nastaviť jeho „zafarbenie,“ môže byť úplne priehľadný alebo mliečne zakalený. Materiál je úplne recyklovateľný.',
        },
        {
          text: 'Obrovskou výhodou je 100% recyklovateľnosť tj. po použití je možné výrobky recyklovať opäť na suroviny. Ide o veľmi pevný materiál s nízkou priepustnosťou plynov a dobrou chemickou odolnosťou pre skladovanie bežných kvapalín ako voda a alkohol. Zároveň je prakticky bez zápachu.',
        },
        {
          text: 'Istou nevýhodou je veľmi dlhá doba rozkladu tzn. je recyklovateľný, ale ťažko rozložiteľný. Ďalším nebezpečím je pomerne vysoká navĺhavosť pôsobením pary, takto ľahko prichádza o svoje dobré vlastnosti vrátane pevnosti. Z pohľadu 3D tlače má široké rozmedzie teplôt a teploty topenia sú pomerne vysoké. Tlač môže prebiehať takto veľmi pomaly.',
        },
      ],
      en: {
        title: 'PET',
        description: 'Polyethylene terephthalate',
        body: [
          {
            text: 'PET (polyethylene terephthalate) alebo polyetyléntereftalát je polyester, ktorý určite poznáte z plastových fľašiek (podľa materiálu sa nazývajú PETky). Výrobou je možné nastaviť jeho „zafarbenie,“ môže byť úplne priehľadný alebo mliečne zakalený. Materiál je úplne recyklovateľný.',
          },
          {
            text: 'Obrovskou výhodou je 100% recyklovateľnosť tj. po použití je možné výrobky recyklovať opäť na suroviny. Ide o veľmi pevný materiál s nízkou priepustnosťou plynov a dobrou chemickou odolnosťou pre skladovanie bežných kvapalín ako voda a alkohol. Zároveň je prakticky bez zápachu.',
          },
          {
            text: 'Istou nevýhodou je veľmi dlhá doba rozkladu tzn. je recyklovateľný, ale ťažko rozložiteľný. Ďalším nebezpečím je pomerne vysoká navĺhavosť pôsobením pary, takto ľahko prichádza o svoje dobré vlastnosti vrátane pevnosti. Z pohľadu 3D tlače má široké rozmedzie teplôt a teploty topenia sú pomerne vysoké. Tlač môže prebiehať takto veľmi pomaly.',
          },
        ],
      },
    },
    [PAGES.materialPETG]: {
      title: 'PETG',
      description: 'Polyethylene terephthalate-glycol modified',
      body: [
        {
          text: 'PETG (polyethylene terephthalate-glycol modified) je kopolymér PET, čo je akási našrobovaná molekula glykolu do pôvodnej štruktúry PET. Takáto modifikácia dáva materiálu odolnosť ABS a ohybnosť PLA, čo je unikátne spojenie vlastností a často ide o najlepší možný výber pre 3D tlač.',
        },
        {
          text: 'Chemická odolnosť na bežné kvapaliny a rigidná štruktúra umožňujú využitie v obaloch na potraviny a kvapaliny a dokonca aj na medicínske použitie! Veľmi dobrá pevnosť, pružnosť a odolnosť proti nárazu je veľmi vhodný pre mechanicky náročnejšie aplikácie. Vrstvy majú veľmi dobrú adhéziu k podložke pri tlači, zmršťuje sa len veľmi málo a je bez zápachu.',
        },
        {
          text: 'Vyššia hustota a vo výsledku teda váha. Pri všetkých výborných vlastnostiach však má aj vyššiu cenu, preto zvážte či je jeho použitie nevyhnutné ;-).',
        },
      ],
      en: {
        title: 'PETG',
        description: 'Polyethylene terephthalate-glycol modified',
        body: [
          {
            text: 'PETG (polyethylene terephthalate-glycol modified) je kopolymér PET, čo je akási našrobovaná molekula glykolu do pôvodnej štruktúry PET. Takáto modifikácia dáva materiálu odolnosť ABS a ohybnosť PLA, čo je unikátne spojenie vlastností a často ide o najlepší možný výber pre 3D tlač.',
          },
          {
            text: 'Chemická odolnosť na bežné kvapaliny a rigidná štruktúra umožňujú využitie v obaloch na potraviny a kvapaliny a dokonca aj na medicínske použitie! Veľmi dobrá pevnosť, pružnosť a odolnosť proti nárazu je veľmi vhodný pre mechanicky náročnejšie aplikácie. Vrstvy majú veľmi dobrú adhéziu k podložke pri tlači, zmršťuje sa len veľmi málo a je bez zápachu.',
          },
          {
            text: 'Vyššia hustota a vo výsledku teda váha. Pri všetkých výborných vlastnostiach však má aj vyššiu cenu, preto zvážte či je jeho použitie nevyhnutné ;-).',
          },
        ],
      },
    },
    [PAGES.materialABS]: {
      title: 'ABS',
      description: 'Acrylonitrile butadiene styrene',
      body: [
        {
          text: 'ABS (acrylonitrile butadiene styrene) ide o materiál s výbornými mechanickými vlastnosťami. Určite ste sa s ním stretli aj vy, používa sa v automobiloch (nárazníky…), vyrábajú sa z neho elektrické kryty, pre svoju odolnosť je súčasťou mnohých športových pomôcok (kanoe…), kuchynských nástrojov, veľmi známou aplikáciou je výroba kociek LEGA. Už z použitia je zrejmé, že ide o trvanlivý materiál.',
        },
        {
          text: 'Vynikajúca je húževnatosť a odolnosť ABS proti nárazom. Má tiež veľmi dobrú tepelnú odolnosť. Ide o vysoko trvanlivý materiál, ktorý má stabilné vlastnosti aj pri vyšších teplotách, narozdiel napr. od PLA sú komponenty z ABS stále aj vo vyhriatom automobile.',
        },
        {
          text: 'Niektoré bežné chemikálie ako napr. alkoholy zhoršujú jeho vlastnosti, je menej chemicky stabilný. Pri 3D tlači zapácha, pripomína zápach horiaceho polystyrénu. Recyklovateľnosť materiálu je horšia, ide o syntetický ropný produkt.',
        },
      ],
      en: {
        title: 'ABS',
        description: 'Acrylonitrile butadiene styrene',
        body: [
          {
            text: 'ABS (acrylonitrile butadiene styrene) ide o materiál s výbornými mechanickými vlastnosťami. Určite ste sa s ním stretli aj vy, používa sa v automobiloch (nárazníky…), vyrábajú sa z neho elektrické kryty, pre svoju odolnosť je súčasťou mnohých športových pomôcok (kanoe…), kuchynských nástrojov, veľmi známou aplikáciou je výroba kociek LEGA. Už z použitia je zrejmé, že ide o trvanlivý materiál.',
          },
          {
            text: 'Vynikajúca je húževnatosť a odolnosť ABS proti nárazom. Má tiež veľmi dobrú tepelnú odolnosť. Ide o vysoko trvanlivý materiál, ktorý má stabilné vlastnosti aj pri vyšších teplotách, narozdiel napr. od PLA sú komponenty z ABS stále aj vo vyhriatom automobile.',
          },
          {
            text: 'Niektoré bežné chemikálie ako napr. alkoholy zhoršujú jeho vlastnosti, je menej chemicky stabilný. Pri 3D tlači zapácha, pripomína zápach horiaceho polystyrénu. Recyklovateľnosť materiálu je horšia, ide o syntetický ropný produkt.',
          },
        ],
      },
    },
    [PAGES.materialTPE]: {
      title: 'TPE',
      description: 'Thermoplastic elastomer',
      body: [
        {
          text: 'TPE (thermoplastic elastomer) sú kombinácie materiálov, kopolyméry („spojené rôzne plasty“) alebo zmesi viacerých polymérov, ktoré ako už vyplýva z názvu majú plastické aj elastické vlastnosti. Často sa tieto materiály nazývajú aj termoplastické „gumy“. Hovoríme o materiáloch v množnom čísle lebo ide asi o 6 skupín materiálov, ktoré dosahujú plasticko-elastické vlastnosti (TPE-o termoplastické polyolefínoelastoméry, TPU termoplastické polyuretány atď.).',
        },
        {
          text: 'Skupiny týchto materiálov sú veľmi pružne a po zdeformovaní získavajú späť pôvodný tvar. Sú to tepelne stabilné materiály s trvanlivými vlastnosťami, no platí to najmä pri styku s nepolárnymi látkami napr. acetón. Nie sú príliš náročné na výrobu, čo znižuje náklady. Ľahko sa recyklujú, 3D tlačové vlákna sú vyrábané hlavne z recyklovaných materiálov.',
        },
        {
          text: 'Menej stabilné v prostredí polárnych látok ako napr. voda, alkoholy ai. Existuje viac skupín a výber najvhodnejšieho materiálu môže byť komplikovanejší, je potrebné sa v problematike týchto materiálov vedieť orientovať.',
        },
      ],
      en: {
        title: 'TPE',
        description: 'Thermoplastic elastomer',
        body: [
          {
            text: 'TPE (thermoplastic elastomer) sú kombinácie materiálov, kopolyméry („spojené rôzne plasty“) alebo zmesi viacerých polymérov, ktoré ako už vyplýva z názvu majú plastické aj elastické vlastnosti. Často sa tieto materiály nazývajú aj termoplastické „gumy“. Hovoríme o materiáloch v množnom čísle lebo ide asi o 6 skupín materiálov, ktoré dosahujú plasticko-elastické vlastnosti (TPE-o termoplastické polyolefínoelastoméry, TPU termoplastické polyuretány atď.).',
          },
          {
            text: 'Skupiny týchto materiálov sú veľmi pružne a po zdeformovaní získavajú späť pôvodný tvar. Sú to tepelne stabilné materiály s trvanlivými vlastnosťami, no platí to najmä pri styku s nepolárnymi látkami napr. acetón. Nie sú príliš náročné na výrobu, čo znižuje náklady. Ľahko sa recyklujú, 3D tlačové vlákna sú vyrábané hlavne z recyklovaných materiálov.',
          },
          {
            text: 'Menej stabilné v prostredí polárnych látok ako napr. voda, alkoholy ai. Existuje viac skupín a výber najvhodnejšieho materiálu môže byť komplikovanejší, je potrebné sa v problematike týchto materiálov vedieť orientovať.',
          },
        ],
      },
    },
    [PAGES.materialPolyamid]: {
      title: 'Polyamid (Nylon)',
      body: [
        {
          text: 'Polyamid alebo často známy pod obchodným názvom Nylon, je polymérny materiál, ktorý patrí medzi prvé úspešné syntetické plasty. Chemickou štruktúrou sú tzv. peptidové väzby, ktoré sa vyskytujú aj pri proteínoch. Vďaka tejto štruktúre je materiál kombináciou extrémnej flexibility a odolnosti.',
        },
      ],
      en: {
        title: 'Polyamid (Nylon)',
        body: [
          {
            text: 'Polyamid alebo často známy pod obchodným názvom Nylon, je polymérny materiál, ktorý patrí medzi prvé úspešné syntetické plasty. Chemickou štruktúrou sú tzv. peptidové väzby, ktoré sa vyskytujú aj pri proteínoch. Vďaka tejto štruktúre je materiál kombináciou extrémnej flexibility a odolnosti.',
          },
        ],
      },
    },
  },
  [PAGES.visuals]: {
    title: 'Visuals',
    description: 'A dashboard data collection for IOT monitoring',
    extension: 'by PRINET LABS',
    about: 'Read more about PrinetVisuals',

    login: {
      email: 'Login',
      password: 'Password',
      signIn: 'Sign in',
    },
    addNew: 'Add new',
    addNewSensor: 'Add new',
    en: {
      title: 'Visuals',
      description: 'A dashboard data collection for IOT monitoring',
      extension: 'by PRINET LABS',
      about: 'Read more about PrinetVisuals',

      login: {
        email: 'Login',
        password: 'Password',
        signIn: 'Sign in',
      },
      addNew: 'Add new',
      addNewSensor: 'Add new',
    },
  },
};

/** Key words **/
export const keywords = [
  '3D',
  '3D tlac',
  '3D tl aciaren',
  '3D printer',
  'Filament',
  'Trojrozmerna tlaciaren',
  'Tlacova struna',
  'PLA',
  'ABS',
  'PETG',
  '1,75mm',
  '1.75mm',
  'Prusa',
  '3D material',
  'Lacne 3D',
  'Kvalitne 3D',
  'Najlepsie 3D',
];

/** Map next & prev button **/
export const sameLevel = [[PAGES.materialPLA, PAGES.materialPET, PAGES.materialPETG, PAGES.materialABS, PAGES.materialTPE, PAGES.materialPolyamid]];

/** Parent (Back) button **/
export const children = [
  { children: [PAGES.printerSelectionTips], parent: PAGES.print },
  {
    children: [PAGES.materialPLA, PAGES.materialPET, PAGES.materialPETG, PAGES.materialABS, PAGES.materialTPE, PAGES.materialPolyamid],
    parent: PAGES.materialsAndProcedures,
  },
  ...config.interests.map((i) => ({ children: i.features.map((f) => f.page), parent: i.button.page })), // Auto map from interest
];

export default config;
