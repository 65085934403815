import React from 'react';
import { useHistory } from 'react-router-dom';
import { get as g } from 'lodash';

import { Common, translate } from '../../../functions';

import './style.css';

export type Project = {
  title: string;
  description?: string;
  body: {
    text: string;
    images: string[];
    title?: string;
    list?: string[];
  }[];
  projectKey: string;
  links: string[];
  subLinks: string[];
  comments: number;
  active?: boolean;
};

type Props = {
  data: Project;
};

const Project = ({ data }: Props) => {
  const url = g(data, 'body[0].images[0]');
  const image = url ? Common.getImageUrl(url) : 'https://i.ibb.co/mHgBgrm/wp2163554-arduino-wallpapers.png';

  const history = useHistory();

  return (
    <div className="project-view" key={data.title} onClick={() => history.push(Common.link(data.projectKey, { detail: 'project' }))}>
      <div className="project-view-header">
        <img className="project-view-image" src={image} alt={data.title} />
      </div>
      <div className="project-view-body">
        <div className="project-view-title">{data.title}</div>
        <div className="project-view-extra">
          <div>{data.comments || 0} Comments</div>
        </div>
        <div className="project-view-description">
          <span>{(data.body[0].text || '').substring(0, 170)}...</span>
          <span className="project-view-description-more">{translate('projects.readMore')}...</span>
        </div>
      </div>
    </div>
  );
};

export default Project;
