import React from 'react';

import Icon from '../../common/SVG';

import './style.css';

type Props = {
  loaded?: boolean;
  dark?: boolean;
};

const Loading = ({ loaded, dark }: Props) => {
  console.log(dark);

  // @todo improve to statefull
  return (
    <div className={`loader-wrap ${loaded ? 'loaded' : ''} `}>
      <div className="loader-wrap-logo-holder">
        <div className="loader-wrap-logo-animation">
          <Icon icon="logoIcon" width={30} height={30} color="#444" />
          {/** <img src={logoIcon} alt="" className="loader-wrap-logo"/> **/}
        </div>
        <Icon icon="logoText" width={100} color="#444" />
        {/** <img src={logoText} alt="" className="loader-wrap-text"/> **/}
      </div>
    </div>
  );
};

export default Loading;
