import React, { ReactNode } from 'react';

import { ButtonBase } from '@mui/material';

import './style.css';

type Props = {
  children: ReactNode;
  onClick: () => void;
};

const ParentButton = ({ children, onClick }: Props) => {
  return (
    <ButtonBase onClick={onClick} classes={{ root: 'parent-button' }}>
      <span>{children}</span>
    </ButtonBase>
  );
};

export default ParentButton;
