import React from 'react';
import { useHistory } from 'react-router-dom';

import { upperFirst, replace } from 'lodash';

import './style.css';

type Props = {
  project?: boolean;
};

const SubHeader = ({ project }: Props) => {
  const history = useHistory();

  const path = () => {
    const items = ['home', ...window.location.pathname.slice(1).split('/')];

    return items.map((s, i) => (
      <>
        <div key={i} className="sub-header-link" onClick={() => items.length !== i + 1 && history.push(`/${s === 'home' ? '' : s}`)}>
          {upperFirst(replace(s, '-', ' '))}
        </div>
        {items.length - 1 !== i && '/'}
      </>
    ));
  };

  return (
    <div className={`sub-header ${project ? 'lighten' : ''}`}>
      <div className="sub-header-path">{path()}</div>
    </div>
  );
};

export default SubHeader;
