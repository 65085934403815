import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { camelCase, includes, replace } from 'lodash';

import { Common, translate } from '../../functions';

import config, { children, sameLevel } from '../../assets/config';

import NotFound from '../utils/NotFound';

import HexaSVG from '../common/HexaSvg';
import Block from '../common/Block';
import ParentButton from './Parent';
import ListItem, { ListItemProps } from './ListItem';
import { LinkData } from '@functions/common';

import './style.css';

type Props = {
  page: string;
};

const Content = ({ page: pageId }: Props) => {
  const [unmount, setUnmount] = useState(false);

  const history = useHistory();

  const { params } = useRouteMatch();

  const page = config.pages[camelCase(pageId || (params as Record<string, string>).pageId)];

  useEffect(() => {
    setTimeout(() => setUnmount(false), 300);
  }, [location.pathname]);

  const goTo = (link: LinkData) => {
    setUnmount(true);

    link = Common.link(link, { content: true });

    setTimeout(() => history.push(link as string), 150);
  };

  const pageName = useMemo(() => {
    const path = window.location.pathname;

    if (pageId) {
      return camelCase(pageId);
    }

    return camelCase(replace(path, '/content/', ''));
  }, [page]);

  const hasParent = useMemo(() => {
    const found = children.find((item) => includes(item.children, pageName));

    return found ? found.parent : false;
  }, [pageName]);

  const hasCousin = useMemo(() => {
    const found = sameLevel.find((item) => includes(item, pageName));

    if (found) {
      const index = found.findIndex((i) => i === pageName);

      return { prev: found[index - 1], next: found[index + 1] };
    }

    return false;
  }, [pageName]);

  const renderParents = () => {
    const parentsNow = [hasParent];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const parents = parentsNow.map((parent) => ({ title: config.pages[parent] && config.pages[parent].title, page: parent })) as {
      title: string;
      page: string;
    }[];

    return (
      <div className={`page-parents ${unmount ? 'hide' : ''}`}>
        {parents.map((parent) => (
          <div className="page-parent" key="parent">
            <div className="page-parent-item">
              <ParentButton onClick={() => goTo(parent.page)}>{parent.title}</ParentButton>
            </div>
            <div className="page-parent-line" />
          </div>
        ))}
      </div>
    );
  };

  const renderSubLinks = () => {
    if (!page.subLinks) return null;

    return (
      <>
        <div className="page-body-title">{translate('subLinks.title')}</div>
        <div className="page-body-sub-links">
          {page.subLinks.links &&
            page.subLinks.links.map((l, i) => (
              <div className="page-body-sub-link-item" onClick={() => goTo(l)} key={i}>
                <HexaSVG icon="hexa" width={90} height={90} color="#E56F16" />
                <div className="page-body-sub-link-text">
                  <span>{translate(`pages[${pageName}].subLinks.links[${i}].text`)}</span>
                </div>
              </div>
            ))}
        </div>
      </>
    );
  };

  const renderLinks = () => {
    if (!page.links) return null;

    return (
      <div className="page-body-list">
        {page.links.map((item, index) => (
          <ListItem
            data={item}
            key={index}
            text={translate(`pages[${pageName}].links[${index}].text`)}
            title={translate(`pages[${pageName}].links[${index}].title`)}
            onClick={() => goTo(item)}
          />
        ))}
      </div>
    );
  };

  const renderPage = () => {
    let content = (
      <div className={`content ${unmount ? 'hide' : ''}`}>
        {page.title && <div className="page-title">{page.title}</div>}
        {page.description && <div className="page-description">{page.description}</div>}
        {page.body && (
          <div className="page-body">
            {page.body.map((item: any, index) => (
              <div className="page-body-section" key={index}>
                {item.title && <div className="page-body-title">{translate(`pages[${pageName}].body[${index}].title`)}</div>}
                {item.text && <div className="page-body-text">{translate(`pages[${pageName}].body[${index}].text`)}</div>}
                {item.list && (
                  <div className="page-body-list">
                    {item.list.map((l: ListItemProps, i: number) => (
                      <ListItem
                        data={l}
                        key={i}
                        text={translate(`pages[${pageName}].body[${index}].list[${i}].text`)}
                        title={translate(`pages[${pageName}].body[${index}].list[${i}].title`)}
                      />
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        {renderSubLinks()}
        {renderLinks()}
      </div>
    );

    if (hasCousin) {
      content = (
        <div className="page-flex">
          <div className="page-left-arrow" onClick={() => goTo(hasCousin.prev)}>
            {hasCousin.prev && <HexaSVG icon="prev" width={40} height={40} color="#E56F16" />}
          </div>
          {content}
          <div className="page-right-arrow" onClick={() => goTo(hasCousin.next)}>
            {hasCousin.next && <HexaSVG icon="next" width={40} height={40} color="#E56F16" />}
          </div>
        </div>
      );
    }

    if (hasParent) {
      content = (
        <div className="page-column">
          {renderParents()}
          {content}
        </div>
      );
    }

    if (pageId) {
      return content;
    }

    return <Block color="white">{content}</Block>;
  };

  if (!page) {
    return <NotFound />;
  }

  return renderPage();
};

export default Content;
