import React from 'react';

import { translate } from '../../../functions';

import './style.css';

export type PaginationOptions = {
  currentPage?: number;
  total: number;
  onChange: (newPage: number) => void;
  secondary?: boolean;
};

const Pagination = ({ currentPage = 1, total, onChange, secondary }: PaginationOptions) => {
  const pages = [];

  for (let i = 1; i <= total; i++) {
    pages.push(
      <div key={i} onChange={() => onChange(i)} className={`pagination-page ${currentPage === i ? 'active' : ''}`}>
        {i}
      </div>
    );
  }

  if (!onChange) {
    return (
      <div className="visuals-pagination-row">
        <div className="visuals-pagination-total">{total}</div>
      </div>
    );
  }

  if (secondary) {
    return (
      <div className="pagination-row">
        <div className="pagination-button-wrap">
          {currentPage > 1 && (
            <button className="pagination-button" onChange={() => onChange(currentPage - 1)}>
              <span>{translate('utils.prev')}</span>
            </button>
          )}
        </div>
        <div className="pagination-info">{`${translate('utils.page')} ${currentPage} ${translate('utils.of')} ${total}`}</div>
        <div className="pagination-button-wrap">
          {total > 1 && (
            <button className="pagination-button" onChange={() => onChange(currentPage + 1)}>
              <span>{translate('utils.next')}</span>
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="pagination">
      <div className="pagination-block">
        {currentPage > 1 && <div className="prev" onChange={() => onChange(currentPage - 1)} />}
        <div className="pagination-pages">{pages}</div>
        {total > 1 && <div className="next" onChange={() => onChange(currentPage + 1)} />}
      </div>
    </div>
  );
};

export default Pagination;
