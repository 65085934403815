import React, { useEffect, useState } from 'react';
import ReactGa from 'react-ga';
import { ButtonBase } from '@mui/material';
import { useHistory } from 'react-router-dom';

import Icon from '../common/SVG';
import NavItem from '../common/NavItem';
import Loading from '../utils/Loading';

import config from '../../assets/config';

import './style.css';

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  const [dataLoaded, setDataLoaded] = useState(false);

  const history = useHistory();

  useEffect(() => {
    ReactGa.initialize(config.googleAnalyticsKey);

    const timeoutId = setTimeout(() => setDataLoaded(true), 300);

    return () => {
      // Cleanup function to clear the timeout if the component unmounts before it fires
      clearTimeout(timeoutId);
    };
  }, []); // Empty dependency array ensures that this effect runs only once on mount

  const year = new Date().getFullYear();

  const extraColor = window.location.pathname.includes('/project') ? 'project-color' : '';

  return (
    <>
      <div className="app">
        <div className={`header ${extraColor}`}>
          <div className="container">
            <ButtonBase onClick={() => history.push('/')} disableRipple>
              <Icon icon="logo" width={130} height={18} />
            </ButtonBase>
            <div className="menu" key={window.location.pathname}>
              <NavItem label="Home" path="/" navigating primary />
              <NavItem label="Services" navigating primary />
              <NavItem label="Products" navigating primary />
              <NavItem label="Projects" navigating primary />
              <NavItem label="Blog" navigating primary />
              <NavItem label="About us" navigating primary />
              <NavItem label="Contact" navigating primary />
            </div>
          </div>
        </div>
        {children}
        <div className="footer">
          <div className="container">
            <div className="extra">
              <Icon icon="logoText" width={80} color="#333" />
            </div>
            <div className="company">
              © {config.company.name} {year}. {config.company.footerExtension || ''}
            </div>
          </div>
        </div>
      </div>
      <Loading loaded={dataLoaded} />
    </>
  );
};

export default Layout;
