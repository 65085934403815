import React, { useState, useEffect } from 'react';
import { sortBy } from 'lodash';

import API from '../../../api';
import Block from '../../common/Block';
import Product, { Product as ProductType } from '../../common/Product';
import Pagination from '../../utils/Pagination';
import Loader from '../../utils/Loader';
import BlogPreview from '../../common/BlogPreview';

import './style.css';

export type ProductList = {
  rows?: ProductType[];
  total?: number;
};

const Products = () => {
  const [data, setData] = useState<ProductList>({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [mount, setMount] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (index?: number) => {
    console.log(index);

    try {
      const response = await API.get('/products/list');
      const newData = response.data;

      setData(newData);
      setDataLoaded(true);

      setTimeout(() => setMount(true), 300);
    } catch (error) {
      // Handle the error as needed
    }
  };

  const products = sortBy(data.rows || [], 'sequence')
    .filter((p) => p.active)
    .map((product) => <Product data={product} key={product.productKey} />);

  return (
    <Block color="white">
      <div className="products-wrap">
        <div className="products-holder">
          {mount && <div className="products">{products}</div>}
          {!mount && (
            <div className="product-loader">
              <Loader loaded={dataLoaded} />
            </div>
          )}
          <Pagination currentPage={1} total={1} onChange={(index) => loadData(index)} />
        </div>
        <BlogPreview />
      </div>
    </Block>
  );
};

export default Products;
