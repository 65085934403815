import React from 'react';

import { isArray } from 'lodash';

import icons from '../../../assets/hexaIcons';

type Props = {
  icon: string;
  width?: number;
  height?: number;
  color?: string;
};

const HexaSVG = ({ icon, color, width = 30, height = 30 }: Props) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const path = icons[icon].path;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const transform = icons[icon].transform;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const viewBox = icons[icon].viewBox;

  let content = <path transform={transform} d={path} fill={color ? color : '#E56F16'} />;

  if (isArray(path)) {
    content = (
      <g transform={transform} fill={color ? color : '#E56F16'}>
        {path.map((p, i) => (
          <path d={p} key={i} />
        ))}
      </g>
    );
  }

  return (
    <svg width={width} height={height} viewBox={viewBox}>
      {content}
    </svg>
  );
};

export default HexaSVG;
