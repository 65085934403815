import React, { ReactNode } from 'react';

import './style.css';

type Props = {
  children?: ReactNode;
  color?: any;
  overflow?: boolean;
  hideOnSmallRes?: boolean;
};

const Block = ({ color, children, overflow, hideOnSmallRes }: Props) => {
  return (
    <div className={`component-block ${color} ${overflow ? 'overflow-hidden' : ''} ${hideOnSmallRes ? 'sm-hide' : ''}`}>
      <div className="container">{children}</div>
    </div>
  );
};

export default Block;
