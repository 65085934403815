import React, { MouseEventHandler, ReactNode } from 'react';
import PropTypes from 'prop-types';

import { Common } from '../../../functions';

import './style.css';
import { useHistory } from 'react-router-dom';

type Props = {
  children: ReactNode;
  onClick?: () => void; // Assuming the component is a button; adjust if it's a different element
  icon?: string;
  primary?: boolean;
  secondary?: boolean;
  className?: string;
  lg?: boolean;
  md?: boolean;
  sm?: boolean;
  type?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  selected?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  link?: string;
};

const getProps = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const type = ['primary', 'secondary', 'tertiary'].filter((type) => props[type])[0];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const size = ['lg', 'md', 'sm'].filter((size) => props[size])[0];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const extend = ['squared'].filter((extend) => props[extend])[0];

  if (!type) console.error('Button expects one of following props: primary, secondary, tertiary');
  if (!size) console.error('Button expects one of following props: lg, md, sm');

  return { size, type, extend };
};

const attachButtonClasses = (props: Props) => {
  const { type, size, extend } = getProps(props);

  return `${extend + 'Root'} ${type + 'Root'} ${size + 'Root'} ${props.fullWidth ? 'fullWidth' : ''} ${props.className || ''}`;
};

const Button = (props: Props) => {
  const history = useHistory();

  const click = () => {
    if (props.onClick) props.onClick();
    if (props.link) history.push(Common.link(props.link, { content: true }));
  };

  return (
    <button
      onClick={click}
      className={`button ${attachButtonClasses(props)} ${props.loading ? 'loading' : ''}`}
      type={props.type || 'button'}
      disabled={props.disabled || false}
    >
      <span className="label">{props.children}</span>
    </button>
  );
};

export default Button;
