import React from 'react';

import Icon from '../Icon';

import './style.css';

type Props = {
  onChange: (e: any) => void;
  value: number | string;
  placeholder?: string;
  required?: boolean;
  error?: boolean;
  label?: string;
  className?: string;
  classes?: Record<string, string>;
  iconStart?: string;
  iconEnd?: string;
  name?: string;
};

const TextField = ({ onChange, value, label, classes = {}, error, placeholder, required, iconStart, iconEnd, ...props }: Props) => {
  const input = (
    <>
      {iconStart && (
        <Icon size={20} className={classes.iconStart || ''}>
          {iconStart}
        </Icon>
      )}
      <input
        className={`text-field ${error ? 'error' : ''} ${classes.input || ''}`}
        onChange={onChange}
        value={value}
        required={required}
        placeholder={required && placeholder ? `${placeholder} *` : placeholder}
        {...props}
      />
      {iconEnd && (
        <Icon size={20} className={classes.iconEnd || ''}>
          {iconEnd}
        </Icon>
      )}
    </>
  );

  if (label) {
    return (
      <div className={`text-field-wrap ${classes.root || ''}`}>
        <div>
          <label className={`text-field-label ${classes.label || ''}`} htmlFor={props.name}>
            {label}
          </label>
          {input}
        </div>
      </div>
    );
  }

  return <label className="label">{input}</label>;
};

export default TextField;
